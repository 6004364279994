import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link, NavLink, Route, Switch, useHistory } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Collapse, Fade, Grid, InputBase, MenuItem, MenuList, Paper, Popover, Popper } from '@material-ui/core';
import { useWindowSize } from '../Controls/ScreenResolution';
import AppContext from '../../App/AppContext';
import { getMenuForRole } from './_menu';
import { WorkflowDash, PartSales, UserProfile, AppraisalDashboard } from '../../Components';
import SearchIcon from '@material-ui/icons/Search';
import { getDataByEwipNo } from '../Service/common-service';
import moment from 'moment';
import { CustomButton, SecondaryButton } from '../FormInput';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MySignature, { UserSignatureReminder } from '../Modal/SignatureModal';
import { postSignature, postSignatureStatus } from '../Service/login-service';
import { AppStorage } from '../Service/storage-service';
import { TypeOfAuth } from './menu/menu-auth-type';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { TickerDisplayMessage } from './ticker';
import { TableContainer } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';
import formatters from '../Grid/inputFormatter';
import InternalMenusToConvertDynamic from '../Root/menu/roles-menu/reportingMenu';
import DialogComp from '../Controls/Dialog/DialogComp';
import SettingsIcon from '@material-ui/icons/Settings';

const renderRoutes = (menus) => {
    return menus.map((menu) => {
        const { label, exact, path, component, children } = menu;
        const ParentPathSegments = path ? path.split('?') : [];

        if (children) {
            return children.map((child) => {
                const { path: childPath, component: childComponent, exact: childExact } = child;
                const childPathSegments = childPath ? childPath.split('?') : [];

                if (child.children) {
                    return renderRoutes(child.children);
                }

                return <Route key={child.label} exact={childExact} path={childPathSegments[0]} component={childComponent} />;
            });
        } else {
            return <Route key={label} exact={exact} path={ParentPathSegments[0]} component={component} />;
        }
    });
};

const drawerWidth = 240;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.hover
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        maxWidth: 800
    },
    root: {
        display: 'flex'
    },
    appBar: {
        top: '60px',
        height: '45px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(0) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1
        }
    },
    toolbar: {
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    app_toolbar: {
        height: '45px',
        minHeight: '45px',
        display: 'flex',
        // justifyContent: 'space-between',
        padding: '0px 10px'
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3)
        overflow: 'auto',
        height: 'calc(100vh - 60px)'
    },
    drawerPaper: {
        top: '60px',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },

    labelBtn: {
        color: (props) => `${props.portalMenuBarTextColour}`,
        textTransform: 'none',
        textDecoration: 'none',
        fontSize: '15px'
    },
    menuIcon: {
        minWidth: '40px'
    },
    logoutBtn: {
        display: 'inline-flex',
        VerticalAlign: 'text-bottom',
        BoxSizing: 'inherit',
        textAlign: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        cursor: 'pointer',
        marginLeft: 1
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        marginTop: (props) => (props.WindowWidths > 750 ? 4 : 0),
        width: '200px !important',
        maxHeight: '30px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 0.9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: '5px 8px 8px 0px', //theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2.5)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    },
    linknav: {
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        textDecoration: 'none',
        color: (props) => `${props.portalMenuBarTextColour}`,
        fontSize: '15px'
    },
    activeLink: {
        '& .MuiTypography-root': {
            color: '#f50057 !important'
        }
    },
    itemText: {
        color: (props) => `${props.portalMenuBarTextColour}`,
        '& span, & svg': {
            fontSize: '0.83rem',
            color: (props) => `${props.portalMenuBarTextColour}`
        }
    },
    ChevronRightIconBtn: {
        color: (props) => `${props.portalMenuBarTextColour}`
    }
}));

export default function MiniDrawer(props) {
    const {
        loggedIn,
        userRoles,
        displayName,
        lastLogin,
        showToast,
        portalSettings,
        showModal,
        showTopNavAndMenu,
        hideShowTopNav,
        reportingMenus,
        tempMenus,
        appraisalMenu
    } = useContext(AppContext);

    const classes = useStyles(portalSettings);

    const theme = useTheme();
    const [screenWidth, screenHeight] = useWindowSize();
    const [open, setOpen] = React.useState((localStorage.getItem('_sideBarIsOpen_') || '').toUpperCase() === 'TRUE' || false);
    const [state, setState] = React.useState({
        isError404: false,
        WorkflowJobs: []
    });
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSign, setOpenSign] = React.useState(false);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed();
    const isInvoiceAllowed = AppStorage.getInvoiceAllowed();
    const isReportingAllowed = AppStorage.getReportingAllowed();
    const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
    let menuPermissionCounter = isCommunicatorAllowed + isInvoiceAllowed + isReportingAllowed + isWIPAllowed;
    let history = useHistory();
    const isReportingAgedMeasureAllowed = AppStorage.getReportingAgedMeasureAllowed();
    const isReportingPartsAllowed = AppStorage.getReportingPartsAllowed();

    const [WindowWidths, WindowHeights] = useWindowSize();
    let isAdmin = userRoles.includes('admin');
    const isInternal = userRoles.includes('internal');
    const currentMenus = useMemo(() => {
        let menus = getMenuForRole(userRoles, loggedIn);
        // if (menuPermissionCounter > 1) {
        //     let firstmenu = menus.find((p) => p.children.length > 0);
        //     setState((st) => ({ ...st, [`${firstmenu.label}`]: true }));
        // }

        if (isWIPAllowed) {
            let dashMenus = [];
            if (tempMenus.menus.length > 0) {
                dashMenus = tempMenus.menus.map((x) => {
                    return {
                        label: `${x.label}`,
                        path: `/dashboard/${x.id}?workFlowId=${x.id}&workFlowName=${x.label}`,
                        exact: true,
                        authType: 1,
                        component: () => {
                            return <WorkflowDash workFlowData={x} />;
                        }
                    };
                });
            }
            if (menuPermissionCounter == 1) {
                let isDash = menus.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[isDash].children = dashMenus;
                }
            } else {
                let iseWIPMenu = menus.findIndex((p) => p.label === 'eWIP');
                let isDash = menus[iseWIPMenu]?.children?.findIndex((p) => p?.isDynamicChildren === 'eWipDashboard');
                if (tempMenus?.menus?.length > 0 && isDash >= 0) {
                    menus[iseWIPMenu].children[isDash].children = dashMenus;
                }
            }
        }
        if (isReportingAllowed) {
            let ReportsIndex = InternalMenusToConvertDynamic.findIndex((m) => m.label == 'Reports');
            let findAgedMeasureMenuIndex = InternalMenusToConvertDynamic[ReportsIndex]?.children?.findIndex((m) => m.label == 'Aged Measures');
            let tempAgedMeasureChildren = InternalMenusToConvertDynamic[ReportsIndex]?.children[findAgedMeasureMenuIndex]?.children;
            const agedMeasureMenus = [];
            const PartsMenus = [];
            const AppraisalMenus = [];

            if (reportingMenus.menus.length > 0) {
                let menusFromAPI = reportingMenus.menus;
                if (tempAgedMeasureChildren?.length > 0) {
                    for (let j = 0; j < menusFromAPI.length; j++) {
                        for (let i = 0; i < tempAgedMeasureChildren?.length; i++) {
                            if (tempAgedMeasureChildren[i].label === menusFromAPI[j].label) {
                                agedMeasureMenus.push({
                                    ...tempAgedMeasureChildren[i],
                                    path: menusFromAPI[j].path,
                                    label: menusFromAPI[j].menuLabel
                                });
                            }
                        }
                    }
                }
                for (let j = 0; j < menusFromAPI.length; j++) {
                    if (menusFromAPI[j].parentMenu == 'Part') {
                        PartsMenus.push({
                            exact: true,
                            authType: 1,
                            path: menusFromAPI[j].path,
                            label: menusFromAPI[j].menuLabel,
                            component: () => {
                                return <PartSales />;
                            }
                        });
                    }
                }
            }
            let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
            if (isReportingMenu > -1) {
                if (isReportingAgedMeasureAllowed || isReportingPartsAllowed) {
                    let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
                    if (isReportingMenu >= 0) {
                        let agedMeasuresIndex = menus[isReportingMenu]?.children.findIndex((p) => p.label === 'Aged Measures');
                        let partsIndex = menus[isReportingMenu]?.children.findIndex((p) => p.label === 'Parts');
                        if (isReportingAgedMeasureAllowed && agedMeasuresIndex >= 0) {
                            menus[isReportingMenu].children[agedMeasuresIndex].children = agedMeasureMenus.length > 0 ? agedMeasureMenus : [];
                        }
                        if (isReportingPartsAllowed && partsIndex >= 0) {
                            menus[isReportingMenu].children[partsIndex].children = PartsMenus.length > 0 ? PartsMenus : [];
                        }
                    }
                }
            }

            // if (appraisalMenu.menus.length > 0) {
            //     let aprrmenusFromAPI = appraisalMenu.menus;
            //     for (let j = 0; j < aprrmenusFromAPI.length; j++) {
            //         if (aprrmenusFromAPI[j].parentMenu == 'Appraisal') {
            //             AppraisalMenus.push({
            //                 exact: true,
            //                 authType: 1,
            //                 path: aprrmenusFromAPI[j].path,
            //                 label: aprrmenusFromAPI[j]?.menuLabel || aprrmenusFromAPI[j]?.label || '',
            //                 component: () => {
            //                     return <AppraisalDashboard />;
            //                 }
            //             });
            //         }
            //     }
            // }
            // let isAppraisalMenu = menus.findIndex((p) => p.label === 'Appraisal');
            // menus[isAppraisalMenu].children = AppraisalMenus.length > 0 ? AppraisalMenus : [];
        }
        return menus;
    }, [loggedIn, tempMenus, reportingMenus]);

    const handleDrawerOpen = (isFlag) => {
        if (isFlag == true) {
            localStorage.setItem('_sideBarIsOpen_', true);
        }
        setOpen(true);
    };

    const handleDrawerClose = (isFlag) => {
        if (isFlag == true) {
            localStorage.setItem('_sideBarIsOpen_', false);
        }

        setOpen(false);
    };

    const handleClick = (item) => {
        setState((st) => ({ ...st, [item]: !st[item] }));
        handleDrawerOpen();
    };

    const getWipDataById = async (wipNo) => {
        const res = await getDataByEwipNo(wipNo);
        if (res.success) {
            setState((st) => ({ ...st, WorkflowJobs: res.data.jobsDetails }));
        }
    };

    const handleChange = (event) => {
        if (event.target.value.length > 4) {
            getWipDataById(event.target.value);
            setSearchAnchorEl(event.currentTarget);
        } else {
            setSearchAnchorEl(null);
        }
    };

    const handleCloseSearch = (data) => {
        localStorage.setItem('currentWorkflowId', data.workFlowID);
        history.push(`/Job/${data.headerRecordID}/${data.workFlowID}`);
        setSearchAnchorEl(null);
        setState((st) => ({ ...st, WorkflowJobs: [] }));
    };

    const handler = (children, isFiltered, isIcon) => {
        let currentNav = children;
        // isFiltered ? _.orderBy(children, [(c) => c.navigationMenuLabel.toLowerCase()], ['asc']) : children;
        return currentNav?.map((subOption, index) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.label}>
                        <NavLink activeClassName={classes.activeLink} exact to={subOption.path} className={classes.linknav}>
                            <ListItem
                                className={classes.linknav}
                                button
                                size="small"
                                key={subOption.label}
                                title={subOption.label}
                                onClick={WindowWidths > 1200 ? handleDrawerOpen : !open ? handleDrawerOpen : handleDrawerClose}
                            >
                                {isIcon ? (
                                    <ListItemIcon style={{ color: portalSettings.portalMenuBarTextColour }} className={classes.menuIcon}>
                                        {subOption.icon}
                                    </ListItemIcon>
                                ) : (
                                    ''
                                )}
                                {!open && !isIcon ? <div className={classes.menuIcon}></div> : ''}
                                <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                            </ListItem>
                        </NavLink>
                    </div>
                );
            }
            return (
                <div key={subOption.label}>
                    <ListItem className={classes.linknav} button onClick={() => handleClick(subOption.label)} title={subOption.label}>
                        {isIcon ? (
                            <ListItemIcon style={{ color: portalSettings.portalMenuBarTextColour }} className={classes.menuIcon}>
                                {subOption.icon}
                            </ListItemIcon>
                        ) : (
                            ''
                        )}
                        {!open && !isIcon ? <div className={classes.menuIcon}></div> : ''}
                        <ListItemText className={classes.itemText}>{subOption.label}</ListItemText>
                        <ListItemIcon
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                minWidth: '35px'
                            }}
                            className={classes.linknav}
                        >
                            {state[subOption.label] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse className={classes.nested} in={state[subOption.label]} timeout="auto" unmountOnExit>
                        {handler(subOption.children, true, false)}
                    </Collapse>
                </div>
            );
        });
    };
    const openInfo = Boolean(anchorEl);
    const id = openInfo ? 'simple-popover' : undefined;
    const handlePopupClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopupClose = () => {
        setAnchorEl(null);
    };

    const closeSign = () => {
        setOpenSign(!openSign);
    };

    const showPasswordChangeModal = (e) => {
        e.preventDefault();
        showModal('PasswordChange');
    };

    const postSignData = async (sign, id, defaultInternalDashboard, appbarLocation) => {
        const data = {
            signature: sign,
            AppbarLocation: appbarLocation,
            defaultInternalDashboard: defaultInternalDashboard
        };
        console.log('from leftbar ');

        let resSign = await postSignature(data);
        let resStatus = id && (await postSignatureStatus(id));
        if (resSign) {
            if (resSign?.success) {
                AppStorage.setSignature(sign);
                AppStorage.setUserGridColumnAppbarLocation(appbarLocation);
                localStorage.setItem('_defaultInternalDashboard_', defaultInternalDashboard);

                showToast('User Settings Updated Successfully!');
            } else {
                showToast(resSign?.message || 'Something went wrong!');
            }
        }
    };

    return (
        <div className={classes.root}>
            {showTopNavAndMenu && (
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open
                    })}
                    style={{
                        backgroundColor: portalSettings.portalMenuBarBackgroundColour,
                        color: portalSettings.portalMenuBarTextColour
                    }}
                >
                    <Toolbar className={classes.app_toolbar}>
                        <Grid container justify="space-between">
                            <Grid item>
                                {loggedIn && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => handleDrawerOpen(true)}
                                        edge="start"
                                        style={{ padding: '0px', marginLeft: '7px' }}
                                        className={clsx(classes.menuButton, {
                                            [classes.hide]: open
                                        })}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item style={{ flex: 1 }}>
                                <Grid container justify="space-between">
                                    <Grid item>
                                        {loggedIn && !isAdmin && isWIPAllowed && (
                                            <div className={classes.search}>
                                                <div className={classes.searchIcon}>
                                                    <SearchIcon />
                                                </div>
                                                <InputBase
                                                    placeholder="Search by WIP/Reg"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput
                                                    }}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                                <Popper
                                                    open={Boolean(searchAnchorEl)}
                                                    anchorEl={searchAnchorEl}
                                                    placement="bottom-start"
                                                    transition
                                                    style={{ zIndex: 9999 }}
                                                >
                                                    {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <Paper
                                                                style={{
                                                                    borderRadius: '5px',
                                                                    maxHeight: '350px',
                                                                    overflow: 'auto'
                                                                }}
                                                            >
                                                                <TableContainer>
                                                                    <Table className={classes.table} aria-label="customized table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell width="9%">
                                                                                    <b>WIP No</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="13%">
                                                                                    <b>Vehicle Reg</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="15%">
                                                                                    <b>Customer Name</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="9%">
                                                                                    <b>Workflow</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="13%">
                                                                                    <b>Return Reason</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="9%">
                                                                                    <b>Branch</b>
                                                                                </StyledTableCell>
                                                                                <StyledTableCell width="19%">
                                                                                    <b>Due Date In</b>
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {state.WorkflowJobs.map((w, index) => {
                                                                                return (
                                                                                    <StyledTableRow key={index} onClick={() => handleCloseSearch(w)}>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.wipNumber}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.regNumber}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.customer}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.workflow}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.returnReason}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{w.branch}</div>
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell component="th" scope="row">
                                                                                            <div>{formatters.DateTimeFormatter(w.dateDueIn)}</div>
                                                                                        </StyledTableCell>
                                                                                    </StyledTableRow>
                                                                                );
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Paper>
                                                        </Fade>
                                                    )}
                                                </Popper>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <div style={{ display: 'inline-block' }}>
                                            {loggedIn ? (
                                                <div style={{ display: 'flex' }}>
                                                    {/* <div>
                                    {isTopNav ? (
                                        <CustomButton
                                            icon={KeyboardArrowUpIcon}
                                            onClick={() => hideShowTopNav(false)}
                                            toolTipTitle="Hide Portal Info"
                                            IconColor="white"
                                        />
                                    ) : (
                                        <CustomButton
                                            icon={KeyboardArrowDownIcon}
                                            onClick={() => hideShowTopNav(true)}
                                            toolTipTitle="Show Portal Info"
                                            IconColor="white"
                                        />
                                    )}
                                </div> */}
                                                    <Grid
                                                        style={{
                                                            marginRight: 2,
                                                            display: 'inline-flex',
                                                            marginTop: -7
                                                        }}
                                                    >
                                                        <TickerDisplayMessage />
                                                    </Grid>
                                                    &nbsp;
                                                    <Grid aria-describedby={id} onClick={handlePopupClick} className={classes.logoutBtn}>
                                                        <Grid container>
                                                            <Grid item>
                                                                <span className={classes.labelBtn}>{displayName}</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <ExpandMore
                                                                // style={{ marginTop: '-5px' }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Popover
                                                        id={id}
                                                        open={openInfo}
                                                        anchorEl={anchorEl}
                                                        onClose={handlePopupClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center'
                                                        }}
                                                    >
                                                        <MenuList className={classes.linknav}>
                                                            <MenuItem>
                                                                <Link to="/logout" className={`${classes.logoutBtn} ${classes.labelBtn}`}>
                                                                    <ExitToAppIcon style={{ marginRight: '4px' }} />
                                                                    <span>Logout</span>
                                                                </Link>
                                                            </MenuItem>
                                                            {/* {!isAdmin && (
                                                                <MenuItem className={classes.labelBtn} onClick={closeSign}>
                                                                    <BorderColorIcon style={{ marginRight: '4px' }} />
                                                                    <span onClick={closeSign}>My Settings</span>
                                                                </MenuItem>
                                                            )}
                                                            <MenuItem className={classes.labelBtn}>
                                                                <VpnKeyIcon style={{ marginRight: '4px' }} />
                                                                <span onClick={showPasswordChangeModal}>Change Password</span>
                                                            </MenuItem> */}
                                                            <MenuItem
                                                                onClick={() => setState((st) => ({ ...st, openSetting: true }))}
                                                                className={classes.labelBtn}
                                                            >
                                                                {/* <Link to="/logout" className={`${classes.logoutBtn} ${classes.labelBtn}`}> */}
                                                                <SettingsIcon style={{ marginRight: '4px' }} />
                                                                <span>Settings</span>
                                                                {/* </Link> */}
                                                            </MenuItem>
                                                            <MenuItem className={classes.labelBtn} disabled>
                                                                {/* <VpnKeyIcon style={{ marginRight: '4px' }} /> */}
                                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                <span>Version 2.7.2</span>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Popover>
                                                </div>
                                            ) : (
                                                <Link to="/login" style={{ textDecoration: 'none', height: '35px' }}>
                                                    <SecondaryButton style={{ height: '35px', fontSize: '15px' }}>Login</SecondaryButton>
                                                </Link>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}
            {state.openSetting ? (
                <DialogComp title={`Settings`} onClose={() => setState((st) => ({ ...st, openSetting: false }))} fullWidth maxWidth="xl">
                    <UserProfile />
                </DialogComp>
            ) : null}
            {openSign && <MySignature onClose={closeSign} postData={postSignData} />}
            {loggedIn && showTopNavAndMenu && (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open
                        })
                    }}
                    PaperProps={{ className: classes.drawerPaper }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={() => handleDrawerClose(true)} className={classes.ChevronRightIconBtn}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {handler(
                            currentMenus.filter((m) => !m.hidden),
                            false,
                            true
                        )}
                    </List>
                </Drawer>
            )}
            <main className={classes.content}>
                {showTopNavAndMenu && <div className={classes.toolbar} />}
                {/* <Grid
                    style={{
                        marginRight: 2,
                        display: screenWidth > 775 ? 'none' : 'block',
                        marginTop: -15
                        // backgroundColor: portalSettings.portalMenuBarBackgroundColour,
                        // color: portalSettings.portalMenuBarTextColour
                    }}
                >
                    <TickerDisplayMessage />
                </Grid> */}

                {/* <Switch>{renderRoutes(currentMenus)}</Switch> */}
                <Switch>
                    {currentMenus.map((m, i) => {
                        return m.children ? (
                            m.children.map((a) => {
                                let aChildpath = [];
                                if (a.path) {
                                    aChildpath = a.path?.split('?');
                                }
                                return a.children ? (
                                    a.children.map((b) => {
                                        let bChildpath = [];
                                        if (b.path) {
                                            bChildpath = b.path?.split('?');
                                        }

                                        return b.children ? (
                                            b.children.map((c) => {
                                                let cChildpath = [];
                                                if (c.path) {
                                                    cChildpath = c.path?.split('?');
                                                }

                                                return <Route key={c.label} exact={c.exact} path={cChildpath[0]} component={c.component}></Route>;
                                            })
                                        ) : (
                                            <Route key={b.label} exact={b.exact} path={bChildpath[0]} component={b.component}></Route>
                                        );
                                    })
                                ) : (
                                    <Route key={a.label} exact={a.exact} path={aChildpath[0]} component={a.component}></Route>
                                );
                            })
                        ) : (
                            <Route key={m.label} exact={m.exact} path={m.path} component={m.component}></Route>
                        );
                    })}
                </Switch>
            </main>
        </div>
    );
}
