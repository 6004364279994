import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import './WeeklyFlashReport.scss';
import PublicIcon from '@material-ui/icons/Public';
import moment from 'moment';
import { useEffect } from 'react';
import { getWeeklyFlashReport } from '../../../../../Core/Service/ReportScreens-service';
import { useState } from 'react';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';

const crumbs = (measuredesc) => [
    { name: 'Home', path: '/' },
    { name: 'DOC', active: true },
    { name: measuredesc, active: true }
];

const setPercentage = (profit, budget) => {
    if (!+profit) {
        return 0;
    } else {
        if (budget == null || budget == 0) {
            return 100;
        } else {
            let value = (+profit / +budget) * 100;

            return Math.round(value);
            // <= 100 ? Math.round(value) : 100;
        }
    }
};

let currentYear = moment().year();

const WeeklyFlashReport = (props) => {
    const [state, setState] = useState({ tableData: {} });
    const [width, height] = useWindowSize();
    console.log(height);
    let mtdSaleProfit = [
        {
            label: 'New Truck',
            Budget: state.tableData.mtdSalesGrossMarginNewtruckBudget,
            Actual: state.tableData.mtdSalesGrossMarginNewtruckActual,
            Variance: state.tableData.mtdSalesGrossMarginNewtruckVariance
        },
        {
            label: 'New Van',
            Budget: state.tableData.mtdSalesGrossMarginNewVanBudget,
            Actual: state.tableData.mtdSalesGrossMarginNewVanActual,
            Variance: state.tableData.mtdSalesGrossMarginNewVanVariance
        },
        {
            label: 'Used Van',
            Budget: state.tableData.mtdSalesGrossMarginUsedVanBudget,
            Actual: state.tableData.mtdSalesGrossMarginUsedVanActual,
            Variance: state.tableData.mtdSalesGrossMarginUsedVanVariance
        },
        {
            label: 'Used Truck',
            Budget: state.tableData.mtdSalesGrossMarginUsedTruckBudget,
            Actual: state.tableData.mtdSalesGrossMarginUsedTruckActual,
            Variance: state.tableData.mtdSalesGrossMarginUsedTruckVariance
        },
        {
            label: 'Total',
            Budget: state.tableData.mtdSalesGrossMarginTotalBudget,
            Actual: state.tableData.mtdSalesGrossMarginTotalActual,
            Variance: state.tableData.mtdSalesGrossMarginTotalVariance
        }
    ];

    let slabValueConfig = [
        {
            title: 'Vehicle Debtors',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData.vehicleDebtorsCountCurrent,
                    perc: state.tableData.vehicleDebtorsAmountCurrentPerc,
                    value: state.tableData.vehicleDebtorsAmountCurrent
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData.vehicleDebtorsCount30daygreater,
                    perc: state.tableData.vehicleDebtorsCount30daygreaterPerc,
                    value: state.tableData.vehicleDebtorsAmount30daygreater
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData.vehicleDebtorsCount60daygreater,
                    perc: state.tableData.vehicleDebtorsCount60daygreaterPerc,
                    value: state.tableData.vehicleDebtorsAmount60daygreater
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData.vehicleDebtorsCount90daygreater,
                    perc: state.tableData.vehicleDebtorsCount90daygreaterPerc,
                    value: state.tableData.vehicleDebtorsAmount90daygreater
                },
                {
                    slabName: 'Total',
                    unit: state.tableData.vehicleDebtorsTotalCount,
                    perc: state.tableData.vehicleDebtorsTotalperc,
                    value: state.tableData.vehicleDebtorsTotalAmount
                }
            ],

            headerClassName: 'g-bg-pink'
        },
        {
            title: 'Aged WIP',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData.agedWIPTotalCount,
                    perc: state.tableData.agedWIPCurrentperc,
                    value: state.tableData.agedWIPTotalAmount
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData.agedWIP30daysgreaterCount,
                    perc: state.tableData.agedWIP30daysgreaterperc,
                    value: state.tableData.agedWIP30daysgreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData.agedWIP60daysgreaterCount,
                    perc: state.tableData.agedWIP60daysgreaterperc,
                    value: state.tableData.agedWIP60daysgreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData.agedWIP90daysgreaterCount,
                    perc: state.tableData.agedWIP90daysgreaterperc,
                    value: state.tableData.agedWIP90daysgreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData.agedWIPTotalCount,
                    perc: state.tableData.agedWIPTotalperc,
                    value: state.tableData.agedWIPTotalAmount
                }
            ],
            headerClassName: 'g-bg-bluegray'
        },
        {
            title: 'Cash Sales',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData.cashSalesCurrentCount,
                    perc: state.tableData.cashSalesCurrentperc,
                    value: state.tableData.cashSalesCurrentAmount
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData.cashSales30daygreaterCount,
                    perc: state.tableData.cashSales30daygreaterperc,
                    value: state.tableData.cashSales30daygreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData.cashSales60daygreaterCount,
                    perc: state.tableData.cashSales60daygreaterperc,
                    value: state.tableData.cashSales60daygreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData.cashSales90daygreaterCount,
                    perc: state.tableData.cashSales90daygreaterperc,
                    value: state.tableData.cashSales90daygreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData.cashSalesTotalCount,
                    perc: state.tableData.cashSalesTotalperc,
                    value: state.tableData.cashSalesTotalAmount
                }
            ],
            headerClassName: 'g-bg-skype'
        },
        {
            title: 'Queries',
            data: [
                {
                    slabName: 'Current',
                    unit: state.tableData.queriesCurrentCount,
                    perc: state.tableData.queriesCurrentperc,
                    value: state.tableData.queriesCurrentAmount
                },
                {
                    slabName: '30 Days',
                    unit: state.tableData.queries30daygreaterCount,
                    perc: state.tableData.queries30daygreaterperc,
                    value: state.tableData.queries30daygreaterAmount
                },
                {
                    slabName: '60 Days',
                    unit: state.tableData.queries60daygreaterCount,
                    perc: state.tableData.queries60daygreaterperc,
                    value: state.tableData.queries60daygreaterAmount
                },
                {
                    slabName: '90 Days',
                    unit: state.tableData.queries90daygreaterCount,
                    perc: state.tableData.queries90daygreaterperc,
                    value: state.tableData.queries90daygreaterAmount
                },
                {
                    slabName: 'Total',
                    unit: state.tableData.queriesTotalCount,
                    perc: state.tableData.queriesTotalperc,
                    value: state.tableData.queriesTotalAmount
                }
            ],
            headerClassName: 'g-bg-cyan'
        }
    ];

    let TruckUsedSale = [
        {
            label: 'MTD',
            Actual: state.tableData.invoiceUsedTruckMTD,
            Budget: state.tableData.budgetUsedTruckMTD
        },
        {
            label: 'YTD',
            Budget: state.tableData.budgetUsedTruckYTD,
            Actual: state.tableData.invoiceUsedTruckYTD
        }
    ];

    let vanUsedSale = [
        {
            label: 'MTD',
            Actual: state.tableData.invoiceUsedVanMTD,
            Budget: state.tableData.budgetInvoiceUsedVanMTD
        },
        {
            label: 'YTD',
            Actual: state.tableData.invoiceUsedVanYTD,
            Budget: state.tableData.budgetInvoiceUsedVanYTD
        }
    ];

    let usedTruckStock = [
        {
            Actual: formatters.ThousandSeparatorWithoutZero(state.tableData.truckUsedStockUnitsCurrentWk),
            Budget: formatters.ThousandSeparatorWithoutZero(state.tableData.truckUsedStockUnitsPriorMonth)
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckUsedStockValueCurrentWk),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckUsedStockValuePriorMonth)
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckAvgvehicleCurrentWk),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckAvgvehiclePriorMonth)
        }
    ];

    let usedVanStock = [
        {
            Actual: formatters.ThousandSeparatorWithoutZero(state.tableData.vanUsedStockUnitsCurrentWk),
            Budget: formatters.ThousandSeparatorWithoutZero(state.tableData.vanUsedStockUnitsPriorMonth)
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanUsedStockValueCurrentWk),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanUsedStockValuePriorMonth)
        },
        {
            Actual: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanAvgvehicleCurrentWk),
            Budget: formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanAvgvehiclePriorMonth)
        }
    ];

    const pullTableData = async () => {
        let res = await getWeeklyFlashReport();
        setState((st) => ({ ...st, tableData: res.data[0] }));
    };

    useEffect(() => {
        pullTableData();
    }, []);

    return (
        <div className="weekly-flash-report-container">
            <BreadCrumbs crumbs={crumbs(props.MeasureDesc)} />
            <Grid container spacing={2}>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-aqua">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Truck Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceTruckMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceTruckMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceTruckYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceTruckYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderTruckMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderTruckYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderTruckWIP)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.totalVolumeTruck)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetVolumeTruck)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetAchievedTruck)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.wIPTruck)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-blue">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Van Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceVanMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceVanMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceVanYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceVanYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderVanMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderVanYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderVanWIP)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.totalVolumeVan)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetVolumeVan)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetAchievedVan)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.wIPVan)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-blue">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>New Maxus Van Sales</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th colspan="2">MTD</th>
                                            <th colspan="2">YTD</th>
                                            <th colspan="3">Orders</th>
                                            <th>{currentYear}</th>
                                            <th>{currentYear}</th>
                                            <th>% of</th>
                                            <th>{currentYear + 1}</th>
                                        </tr>
                                        <tr>
                                            <th>Invoiced </th>
                                            <th>Budget </th>
                                            <th>Invoiced </th>
                                            <th>Budget</th>
                                            <th>MTD </th>
                                            <th>YTD</th>
                                            <th>WIP</th>
                                            <th>Total Volume</th>
                                            <th>Budget Volume</th>
                                            <th>Budget Achieved</th>
                                            <th>WIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceMaxusMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceMaxusMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceMaxusYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceMaxusYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderMaxusMTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderMaxusYTD)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.orderMaxusWIP)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.totalVolumeMaxus)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetVolumeMaxus)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetAchievedMaxus)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.wIPMaxus)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-black">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Used Sales</span>
                            </div>
                            <Grid container>
                                <Grid item xs={12} md={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive noBorder-Container">
                                                <table className="table u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="4">Used Truck Sales</th>
                                                        </tr>
                                                        <tr>
                                                            <th align="center">MTD</th>
                                                            <th align="center">YTD</th>
                                                        </tr>
                                                        {/*   <tr>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                        </tr> */}
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            {TruckUsedSale.map((o, i) => (
                                                                <td>
                                                                    {' '}
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        justifyContent={'space-around'}
                                                                        alignItems="center"
                                                                        key={`saleprofit${i}`}
                                                                    >
                                                                        <Grid item xs={6} sm={4} md={7}>
                                                                            <div style={{ textAlign: 'center', marginLeft: 20, height: 80, width: 80 }}>
                                                                                <CircularProgressbar
                                                                                    value={setPercentage(o.Actual, o.Budget)}
                                                                                    text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                                                    styles={buildStyles({
                                                                                        textColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        pathColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        trailColor: '#d6d6d6',
                                                                                        textSize: '27px'
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={4} md={5}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'space-between',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <Typography style={{ fontSize: 12 }}>Invoiced</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600,
                                                                                            color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {formatters.ThousandSeparatorWithoutZero(o.Actual)}
                                                                                    </Typography>

                                                                                    <Typography style={{ fontSize: 12 }}>Budget &nbsp;</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600
                                                                                            // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {formatters.ThousandSeparatorWithoutZero(o.Budget)}
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            ))}
                                                            {/* <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedTruckMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetUsedTruckMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedTruckYTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetUsedTruckYTD)}</td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive noBorder-Container">
                                                <table className="table  u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="4">Used Van Sales</th>
                                                        </tr>
                                                        <tr>
                                                            <th align="center">MTD</th>
                                                            <th align="center">YTD</th>
                                                        </tr>
                                                        {/*   <tr>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                        </tr> */}
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            {vanUsedSale.map((o, i) => (
                                                                <td>
                                                                    {' '}
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        justifyContent={'space-around'}
                                                                        alignItems="center"
                                                                        key={`saleprofit${i}`}
                                                                    >
                                                                        <Grid item xs={6} sm={4} md={7}>
                                                                            <div style={{ textAlign: 'center', marginLeft: 20, height: 70, width: 70 }}>
                                                                                <CircularProgressbar
                                                                                    value={setPercentage(o.Actual, o.Budget)}
                                                                                    text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                                                    styles={buildStyles({
                                                                                        textColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        pathColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        trailColor: '#d6d6d6',
                                                                                        textSize: '27px'
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={4} md={5}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'space-between',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <Typography style={{ fontSize: 12 }}>Invoiced</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600,
                                                                                            color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {formatters.ThousandSeparatorWithoutZero(o.Actual)}
                                                                                    </Typography>

                                                                                    <Typography style={{ fontSize: 12 }}>Budget &nbsp;</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600
                                                                                            // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {formatters.ThousandSeparatorWithoutZero(o.Budget)}
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            ))}
                                                            {/* <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedTruckMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetUsedTruckMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedTruckYTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetUsedTruckYTD)}</td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="4">Used Van Sales</th>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="2">MTD</th>
                                                            <th colspan="2">YTD</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                            <th>Invoiced</th>
                                                            <th>Budget</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedVanMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceUsedVanMTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.invoiceUsedVanYTD)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.budgetInvoiceUsedVanYTD)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive noBorder-Container">
                                                <table className="table u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={3}>Used Truck Stocks</th>
                                                            {/* <th>Current Month</th>
                                                            <th>(Prior Month)</th> */}
                                                        </tr>
                                                        <tr>
                                                            <th align="center">Used Stock Units</th>
                                                            <th align="center">Used Stock Value</th>
                                                            <th align="center">Avg / vehicle</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {usedTruckStock.map((o, i) => (
                                                                <td>
                                                                    {' '}
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        justifyContent={'space-around'}
                                                                        alignItems="center"
                                                                        key={`saleprofit${i}`}
                                                                    >
                                                                        <Grid item xs={6} sm={4} md={7}>
                                                                            <div style={{ textAlign: 'center', marginLeft: 20, height: 80, width: 80 }}>
                                                                                <CircularProgressbar
                                                                                    value={setPercentage(o.Actual, o.Budget)}
                                                                                    text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                                                    styles={buildStyles({
                                                                                        textColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        pathColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        trailColor: '#d6d6d6',
                                                                                        textSize: '27px'
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={4} md={5}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'space-between',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <Typography style={{ fontSize: 12 }}> Current Month</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600,
                                                                                            color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {o.Actual}
                                                                                    </Typography>

                                                                                    <Typography style={{ fontSize: 12 }}>(Prior Month) </Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600
                                                                                            // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {o.Budget}
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive noBorder-Container">
                                                <table className="table u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={3}>Used Van Stocks</th>
                                                            {/* <th>Current Month</th>
                                                            <th>(Prior Month)</th> */}
                                                        </tr>
                                                        <tr>
                                                            <th align="center">Used Stock Units</th>
                                                            <th align="center">Used Stock Value</th>
                                                            <th align="center">Avg / vehicle</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {usedVanStock.map((o, i) => (
                                                                <td>
                                                                    {' '}
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        justifyContent={'space-around'}
                                                                        alignItems="center"
                                                                        key={`saleprofit${i}`}
                                                                    >
                                                                        <Grid item xs={6} sm={4} md={7}>
                                                                            <div style={{ textAlign: 'center', marginLeft: 20, height: 80, width: 80 }}>
                                                                                <CircularProgressbar
                                                                                    value={setPercentage(o.Actual, o.Budget)}
                                                                                    text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                                                    styles={buildStyles({
                                                                                        textColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        pathColor:
                                                                                            setPercentage(o.Actual, o.Budget) < 50
                                                                                                ? '#D92641'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 50 &&
                                                                                                  setPercentage(o.Actual, o.Budget) < 80
                                                                                                ? '#ff9f00'
                                                                                                : setPercentage(o.Actual, o.Budget) >= 80
                                                                                                ? '#569101'
                                                                                                : '#D92641',
                                                                                        trailColor: '#d6d6d6',
                                                                                        textSize: '27px'
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid item xs={6} sm={4} md={5}>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'space-between',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'left'
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <Typography style={{ fontSize: 12 }}> Current Month</Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600,
                                                                                            color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {o.Actual}
                                                                                    </Typography>

                                                                                    <Typography style={{ fontSize: 12 }}>(Prior Month) </Typography>
                                                                                    <Typography
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            fontWeight: 600
                                                                                            // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                                        }}
                                                                                    >
                                                                                        {o.Budget}
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Used Truck Stocks</th>
                                                            <th>Current Month</th>
                                                            <th>(Prior Month)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Used Stock Units</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.truckUsedStockUnitsCurrentWk)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.truckUsedStockUnitsPriorMonth)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Used Stock Value</td>
                                                            <td>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckUsedStockValueCurrentWk)}
                                                            </td>
                                                            <td>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckUsedStockValuePriorMonth)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avg / vehicle</td>
                                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckAvgvehicleCurrentWk)}</td>
                                                            <td>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.truckAvgvehiclePriorMonth)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid> */}

                                        {/* <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Used Van Stocks</th>
                                                            <th>Current Month</th>
                                                            <th>(Prior Month)</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td>Used Stock Units</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.vanUsedStockUnitsCurrentWk)}</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.vanUsedStockUnitsPriorMonth)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Used Stock Value</td>
                                                            <td>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanUsedStockValueCurrentWk)}
                                                            </td>
                                                            <td>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanUsedStockValuePriorMonth)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Avg / vehicle</td>
                                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanAvgvehicleCurrentWk)}</td>
                                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.vanAvgvehiclePriorMonth)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-black">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Aftersales</span>
                            </div>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <Grid container>
                                                    <Grid xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                marginBottom: 10
                                                            }}
                                                        >
                                                            Parts MTD % of Flex Budget
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <div style={{ textAlign: 'center', height: 80, width: 80 }}>
                                                            <CircularProgressbar
                                                                value={20}
                                                                text={`${20}%`}
                                                                styles={buildStyles({
                                                                    textColor:
                                                                        20 < 50
                                                                            ? '#D92641'
                                                                            : 20 >= 50 && 20 < 80
                                                                            ? '#ff9f00'
                                                                            : 20 >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    pathColor:
                                                                        20 < 50
                                                                            ? '#D92641'
                                                                            : 20 >= 50 && 20 < 80
                                                                            ? '#ff9f00'
                                                                            : 20 >= 80
                                                                            ? '#569101'
                                                                            : '#D92641',
                                                                    trailColor: '#d6d6d6',
                                                                    textSize: '27px'
                                                                })}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 18, textAlign: 'left' }}>Trade</Typography>

                                                        <Typography
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(0)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 18, textAlign: 'left' }}>Workshop</Typography>

                                                        <Typography
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(0)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 18, textAlign: 'left' }}>Total</Typography>

                                                        <Typography
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(0)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <Grid container>
                                                    <Grid xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                marginBottom: 10
                                                            }}
                                                        >
                                                            Service MTD % of Flex Budget
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 18, textAlign: 'left' }}>Attended Hours</Typography>
                                                        <Typography
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 21,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left',
                                                                marginLeft: 20
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesAttendedHours)}
                                                        </Typography>
                                                        <div
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                padding: 10,
                                                                textAlign: 'center',
                                                                maxWidth: 60,
                                                                background: 'lightgrey',
                                                                borderRadius: '20px'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesAttendedHours)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 18, textAlign: 'left' }}>Booked Hours</Typography>
                                                        <Typography
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 21,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left',
                                                                marginLeft: 20
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesServiceBookedHours)}
                                                        </Typography>
                                                        <div
                                                            // onClick={() => onRedirect({ startDateType: 'mtd', dc: 'item.dc' })}
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                padding: 10,
                                                                textAlign: 'center',
                                                                maxWidth: 60,
                                                                width: 'auto',
                                                                background: 'lightgrey',
                                                                borderRadius: '20px'
                                                            }}
                                                        >
                                                            {formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesServiceBookedHours)}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">Service MTD % of Flex Budget</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Attended Hours</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesAttendedHours)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Booked Hours</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.aftersalesServiceBookedHours)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} md={8}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="table-container table-responsive ">
                                                <table className="table table-bordered u-table--v1 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3">Service Productivity</th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th width="25%">% of Budget Achieved</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Open'g WIP</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.serviceProductivityOpenWIP)}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hours Sold</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.serviceProductivityHoursSold)}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Current WIP</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.serviceProductivityCurrentWIP)}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Prod. Hours</td>
                                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.serviceProductivityProdHours)}</td>
                                                            <td>
                                                                {formatters.ThousandSeparatorWithoutZero(state.tableData.serviceProductivityBudgetAchieved)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12} md={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-green">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Forecast</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th>Forecast</th>
                                            <th>Actual</th>
                                            <th>Budget</th>
                                            <th>% of Budget</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Parts Gross Margin v Budget</td>
                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.forecastActualPartsGrossMargin)}</td>
                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.forecastBudgetPartsGrossMargin)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.forecastpercBudgetPartsGrossMargin)}</td>
                                        </tr>
                                        <tr>
                                            <td>Labour Revenue v Budget</td>
                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.forecastActualLabourRevenue)}</td>
                                            <td>{formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.forecastBudgetLabourRevenue)}</td>
                                            <td>{formatters.ThousandSeparatorWithoutZero(state.tableData.forecastpercBudgetLabourRevenue)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-green">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>Outstanding Cont.</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <table className="table table-bordered u-table--v1 mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Current Month</th>
                                            <th>(Prior Month)</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Impacting on cash flow</td>
                                            <td>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.outstandingContCurrentWkImpactingcashflow)}
                                            </td>
                                            <td>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.outstandingContPriorWeekImpactingcashflow)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pending Registrations</td>
                                            <td>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.outstandingContCurrentWkPendingRegistrations)}
                                            </td>
                                            <td>
                                                {formatters.CurrencyThousandSeparatorWithoutZero(state.tableData.outstandingContPriorWeekPendingRegistrations)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>

                    {slabValueConfig.map((p) => {
                        return (
                            <Grid item xs={12} md={6}>
                                <div className="component-container g-brd-blue">
                                    <div className={`header-container ${p.headerClassName}`}>
                                        <span className="icon-container">
                                            <PublicIcon />
                                        </span>
                                        <span>{p.title}</span>
                                    </div>
                                    <div className="table-container table-responsive ">
                                        <Grid container justifyContent="space-evenly" alignItems="center" className="slabCard-container">
                                            {p.data.map((q) => {
                                                return (
                                                    <Grid item>
                                                        <Grid container direction="column" alignItems="center">
                                                            <Grid item className="slabValue3_Text">
                                                                {q.slabName}
                                                                <span>{`(${q.unit ? q.unit : 0})`}</span>
                                                            </Grid>
                                                            <Grid item className="slabValue1_Text">
                                                                {q.value ? formatters.CurrencyThousandSeparatorWithoutZero(q.value) : 0}
                                                            </Grid>
                                                            <Grid item className="slabValue3_Text" style={{ fontWeight: 'bold' }}>
                                                                {q.perc ? parseFloat(q.perc).toFixed(2) : 0}%
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <div className="component-container g-brd-blue">
                            <div className="header-container g-bg-gray-dark-v5">
                                <span className="icon-container">
                                    <PublicIcon />
                                </span>
                                <span>MTD Sales Profit</span>
                            </div>
                            <div className="table-container table-responsive ">
                                <Grid container justifyContent={width > 1280 ? 'space-around' : 'flex-start'} alignItems="center">
                                    {mtdSaleProfit.map((o, i) => {
                                        return (
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                sm={6}
                                                md={3}
                                                lg={2}
                                                xl
                                                justifyContent={'space-around'}
                                                alignItems="center"
                                                key={`saleprofit${i}`}
                                                style={{ borderRight: width > 598 && mtdSaleProfit.length - 1 != i && '1px solid rgba(0,0,0,0.1)' }}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: 600, marginLeft: 30 }}>{o.label}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={4} md={7}>
                                                    <div style={{ textAlign: 'center', marginLeft: 20, height: 110, width: 110 }}>
                                                        <CircularProgressbar
                                                            value={setPercentage(o.Actual, o.Budget)}
                                                            text={`${setPercentage(o.Actual, o.Budget)}%`}
                                                            styles={buildStyles({
                                                                textColor:
                                                                    setPercentage(o.Actual, o.Budget) < 50
                                                                        ? '#D92641'
                                                                        : setPercentage(o.Actual, o.Budget) >= 50 && setPercentage(o.Actual, o.Budget) < 80
                                                                        ? '#ff9f00'
                                                                        : setPercentage(o.Actual, o.Budget) >= 80
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                pathColor:
                                                                    setPercentage(o.Actual, o.Budget) < 50
                                                                        ? '#D92641'
                                                                        : setPercentage(o.Actual, o.Budget) >= 50 && setPercentage(o.Actual, o.Budget) < 80
                                                                        ? '#ff9f00'
                                                                        : setPercentage(o.Actual, o.Budget) >= 80
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                trailColor: '#d6d6d6',
                                                                textSize: '27px'
                                                            })}
                                                        />
                                                    </div>
                                                </Grid>

                                                <Grid item xs={6} sm={4} md={5}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <div>
                                                            <Typography style={{ fontSize: 12 }}>Actual</Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 600,
                                                                    color: o.Actual ? (o.Actual <= o.Budget ? 'red' : 'green') : 'red'
                                                                }}
                                                            >
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(o.Actual)}
                                                            </Typography>

                                                            <Typography style={{ fontSize: 12 }}>Budget &nbsp;</Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 600
                                                                    // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                }}
                                                            >
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(o.Budget)}
                                                            </Typography>
                                                            <Typography style={{ fontSize: 12 }}>Variance &nbsp;</Typography>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 15,
                                                                    fontWeight: 600
                                                                    // color: o.actualSale ? (o.actualSale <= state.budget[0].dailyBudget ? 'red' : 'green') : 'red'
                                                                }}
                                                            >
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(o.Variance)}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default WeeklyFlashReport;
