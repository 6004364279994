import { Grid, FormLabel, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { YesNoButton, Multiselect, PrimaryCheckbox } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { useContext, useEffect, useState } from 'react';

export default function EwipModules(props) {
    const [list, setList] = useState([]);
    const [value, setValue] = useState([]);

    const { handleClick, state, handleCheckbox, selectedData, masterData, AutoWorkChange, processData, AutoPrChange } = props;
    const { portalSettings } = useContext(AppContext);

    useEffect(() => {
        if (props.process.length > 0) {
            let FilterList = [];
            props.process.forEach((m) => {
                let tempArr = [];
                let filteredList = [];
                tempArr = [...masterData.workflows].filter((k) => k.processID === m.id);
                if (state.userType === 'technician') {
                    filteredList = tempArr.filter((item) => item.technicianAllowed);
                } else if (state.userType === 'internal') {
                    filteredList = tempArr.filter((item) => item.internalAllowed);
                }
                FilterList = [...FilterList, ...filteredList];
            });

            setList([...FilterList]);
            setValue([...selectedData.workflows]);
        } else {
            setList([]);
            setValue([]);
        }
    }, [props.process, state.userType]);

    return (
        <Grid container spacing={1}>
            {/* workflow-start */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.eWIPAllowed} name="eWIPAllowed" value={state.eWIPAllowed} />}
                    label="eWIP Allowed?"
                />
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allprocess} name="allprocess" value={state.allprocess} />}
                        label="All Processes"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Multiselect label="Processes" options={processData.processes} value={selectedData.processes} onChange={AutoPrChange} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allworkflow} name="allworkflow" value={state.allworkflow} />}
                    label="All Workflows"
                />
            </Grid>
            {!state.allworkflow ? <Multiselect label="Workflow" options={list} value={value} onChange={AutoWorkChange} /> : null}
            {/* workflow-end */}

            <Grid container spacing={1} item style={{ paddingLeft: 6 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Override Mandatory Steps?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canOverrideMandatorySteps}
                            name="canOverrideMandatorySteps"
                            onYesClick={() => handleClick('canOverrideMandatorySteps', true)}
                            onNoClick={() => handleClick('canOverrideMandatorySteps', false)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Assign Jobs to Auditor?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canAssignJobsToAuditor}
                            onNoClick={() => handleClick('canAssignJobsToAuditor', false)}
                            onYesClick={() => handleClick('canAssignJobsToAuditor', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Return Jobs From Last Workflow?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canReturnJobsFromLastWorkflow}
                            onNoClick={() => handleClick('canReturnJobsFromLastWorkflow', false)}
                            onYesClick={() => handleClick('canReturnJobsFromLastWorkflow', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Delete Documents?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canDeleteDocuments}
                            onNoClick={() => handleClick('canDeleteDocuments', false)}
                            onYesClick={() => handleClick('canDeleteDocuments', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Reset Password?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canResetPassword}
                            onNoClick={() => handleClick('canResetPassword', false)}
                            onYesClick={() => handleClick('canResetPassword', true)}
                        />
                    </Grid>
                </Grid>
                {state.userType == 'internal' && (
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Assign Job Priority?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canAssignJobPriority}
                                onNoClick={() => handleClick('canAssignJobPriority', false)}
                                onYesClick={() => handleClick('canAssignJobPriority', true)}
                            />
                        </Grid>
                    </Grid>
                )}
                {state.userType == 'internal' && (
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Assign Customer Rating?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canSetCustomerRating}
                                onNoClick={() => handleClick('canSetCustomerRating', false)}
                                onYesClick={() => handleClick('canSetCustomerRating', true)}
                            />
                        </Grid>
                    </Grid>
                )}
                {state.userType == 'internal' && (
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Can Assign Technician?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.canAssignTechnician}
                                onNoClick={() => handleClick('canAssignTechnician', false)}
                                onYesClick={() => handleClick('canAssignTechnician', true)}
                            />
                        </Grid>
                    </Grid>
                )}

                {state.userType == 'internal' && portalSettings.portalSettingSpecialEquipmentEnabled && (
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid item xs={12}>
                            Special Equipment Allowed?
                        </Grid>
                        <Grid item xs={10}>
                            <YesNoButton
                                state={state.specialEquipmentAllowed}
                                onNoClick={() => handleClick('specialEquipmentAllowed', false)}
                                onYesClick={() => handleClick('specialEquipmentAllowed', true)}
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Amend To Do List?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canAmendToDoList}
                            onNoClick={() => handleClick('canAmendToDoList', false)}
                            onYesClick={() => handleClick('canAmendToDoList', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Amend Tech Write Up?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canAmendTechWriteUp}
                            onNoClick={() => handleClick('canAmendTechWriteUp', false)}
                            onYesClick={() => handleClick('canAmendTechWriteUp', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Sign Off Tech Write Up?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canSignOffTechWriteup}
                            onNoClick={() => handleClick('canSignOffTechWriteup', false)}
                            onYesClick={() => handleClick('canSignOffTechWriteup', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Can Amend Job Notes?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.canAmendJobNotes}
                            onNoClick={() => handleClick('canAmendJobNotes', false)}
                            onYesClick={() => handleClick('canAmendJobNotes', true)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12}>
                        Auto Load Documents?
                    </Grid>
                    <Grid item xs={10}>
                        <YesNoButton
                            state={state.autoLoadDocuments}
                            onNoClick={() => handleClick('autoLoadDocuments', false)}
                            onYesClick={() => handleClick('autoLoadDocuments', true)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
