import React, { useState, useEffect, Suspense } from 'react';
import './App.scss';
import { Header } from './../Core/Header';
import AppContext from './AppContext';
import { AppStorage } from '../Core/Service/storage-service';
import { Toast } from './../Core/Toast';
import { get, httpVerbEmitter } from '../Core/Service/http-calls';
import { ProfileModal } from '../Core/Modal';
import { getAllowedWorkflow } from '../Core/Service/workflow-service';
import { getGridFilterList } from '../Core/Service/workflow-service';
import { Customi18 } from '../Core/Controls/i18react/customei18';
import { getMenuItems } from '../Core/Service/menuService';

const Loader = () => (
    <div className="App">
        <div>loading...</div>
    </div>
);

function App() {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';

    const portalSettings = AppStorage.getPortalSetting() || { expired: true };
    const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
    const isReportingAllowed = AppStorage.getReportingAllowed() && portalSettings.portalSettingReportingEnabled;
    const isApprisalAllowed = AppStorage.getAppraisalPortalAllowed() && portalSettings.portalSettingsAppraisalEnabled;

    const [contextState, setContextState] = useState({
        loggedIn: AppStorage.isLoggedIn(),
        userRoles: AppStorage.getUserRoles(),
        displayName: AppStorage.getName(),
        lastLogin: AppStorage.getLastLogin(),
        canOverrideMandatorySteps: AppStorage.getCanOverrideMandatorySteps(),
        canAssignJobsToAuditor: AppStorage.getCanAssignJobsToAuditor(),
        canResetPassword: AppStorage.getCanResetPassword(),
        portalSettings: portalSettings,
        snackbar_open: false,
        snackbar_message: '',
        visibleModalId: '',
        showTopNavAndMenu: fromStep ? false : true,
        isTopNav: true
    });
    const { i18n } = Customi18();
    const [tempMenus, setTempMenus] = useState({ menus: [], role: '', menuName: '' });
    const [reportingMenus, setReportingMenus] = useState({ menus: [], role: '', menuName: '' });
    const [appraisalMenu, setAppraisalMenu] = useState({ menus: [], role: '', menuName: '' });

    const [isAppReaload, setisAppReaload] = useState(null);

    const showToast = (msg) => {
        setContextState((st) => ({ ...st, snackbar_open: true, snackbar_message: msg }));
    };

    const hideToast = () => {
        setContextState((st) => ({ ...st, snackbar_open: false, snackbar_message: null }));
    };

    const showModal = (modalId) => {
        setContextState((st) => ({ ...st, visibleModalId: modalId }));
    };

    const hideModal = () => {
        setContextState((st) => ({ ...st, visibleModalId: '' }));
    };

    const hideTopNavAndMenu = () => {
        setContextState((st) => ({ ...st, showTopNavAndMenu: false }));
    };

    const hideShowTopNav = (val) => {
        setContextState((st) => ({ ...st, isTopNav: val }));
    };

    useEffect(() => {
        async function getPortalSetting() {
            if (portalSettings.expired) {
                const res = await get(`portalSetting`);
                if (res.data.details[0]) {
                    AppStorage.setPortalSetting(res.data.details[0]);
                    setContextState((st) => ({ ...st, portalSettings: AppStorage.getPortalSetting() }));
                }
            } else {
                setContextState((st) => ({ ...st, portalSettings: AppStorage.getPortalSetting() }));
            }
        }
        getPortalSetting();

        const listenMsg = ({ data }) => {
            if (data.type === 'trigger-logout') {
                console.log('Logout Trigger from App.jsx received data.type = trigger-logout');
                AppStorage.performLogout();
                window.location = '/';
                window.location.reload(true);
            }
        };
        window.addEventListener('message', listenMsg);
        return () => {
            window.removeEventListener('message', listenMsg);
        };
    }, []);

    useEffect(() => {
        window.document.title = portalSettings.portalSettingTitle || 'eWIP';
    }, [contextState.portalSettings]);

    httpVerbEmitter.addEventListener((e) => {
        console.log('from app', e);
    });

    useEffect(async () => {
        if (!fromStep) {
            if (contextState.loggedIn && isWIPAllowed) {
                setUserPreference();
            }
            if (contextState.loggedIn && contextState.userRoles == 'internal') {
                if (isWIPAllowed) {
                    let res = await getAllowedWorkflow();
                    setTempMenus({ menus: res.data.list.table, menuName: 'Dashboard' });
                }
                let ReportsMenus = await getMenuItems();
                if (isReportingAllowed) {
                    setReportingMenus({ menus: ReportsMenus.data?.list || [], menuName: 'Reporting' });
                }
                // if (isApprisalAllowed) {
                setAppraisalMenu({ menus: ReportsMenus?.data?.list?.filter((k) => k.parentMenu === 'Appraisal'), menuName: 'Appraisal' });
                // }
            }
        }
    }, []);

    const setUserPreference = async () => {
        let res = await getGridFilterList();
        if (res.success) {
            i18n.changeLanguage(res.data?.details[0]?.languageCode || 'en');
            localStorage.setItem('_userPreference_', JSON.stringify(res.data));
            setisAppReaload(new Date());
        }
    };

    const getUserPreference = () => {
        return JSON.parse(localStorage.getItem('_userPreference_'));
    };

    return (
        <Suspense fallback={<Loader />}>
            <AppContext.Provider
                value={{
                    ...contextState,
                    showToast,
                    hideToast,
                    hideModal,
                    showModal,
                    hideTopNavAndMenu,
                    hideShowTopNav,
                    tempMenus,
                    reportingMenus,
                    appraisalMenu,
                    setUserPreference,
                    getUserPreference,
                    isAppReaload
                }}
            >
                <div className="App">
                    <Header />
                </div>
                <Toast />
                <ProfileModal />
            </AppContext.Provider>
        </Suspense>
    );
}

export default App;
