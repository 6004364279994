import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import './salesDashboardSetting.scss';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useEffect } from 'react';
import { getLeagueTableByData, getNewStockbyID, getUsedStockabyID } from '../../../../../Core/Service/SalesPerformance-service';
import { CircularProgress, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import moment from 'moment';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb';
import { SelectBox } from '../../../../../Core/FormInput';
import { react } from '@babel/types';
import { forEach } from 'lodash';

const useStyles = makeStyles({
    table: {
        minWidth: 400,
        background: 'white',
        border: '2px solid white'
    },
    root: {
        flexGrow: 1
        // backgroundColor: theme.palette.background.paper
    }
});

const year = new Date().getFullYear();
const years = Array.from(new Array(10), (val, index) => year - index);
let YearList = years.map((m) => ({
    name: m,
    id: m
}));

let newMonth = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

let Months = [
    {
        name: 'Q1',
        list: [
            { id: 0, month: 'jan', name: 'January' },
            { id: 1, month: 'feb', name: 'February' },
            { id: 2, month: 'mar', name: 'March' }
        ]
    },
    {
        name: 'Q2',
        list: [
            { id: 3, month: 'apr', name: 'April' },
            { id: 4, month: 'may', name: 'May' },
            { id: 5, month: 'june', name: 'June' }
        ]
    },
    {
        name: 'Q3',
        list: [
            { id: 6, month: 'jul', name: 'July' },
            { id: 7, month: 'aug', name: 'August' },
            { id: 8, month: 'sep', name: 'September' }
        ]
    },

    {
        name: 'Q4',
        list: [
            { id: 9, month: 'oct', name: 'October' },
            { id: 10, month: 'nov', name: 'November' },
            { id: 11, month: 'dec', name: 'December' }
        ]
    }
];

const ManiHederCell = withStyles((theme) => ({
    head: {
        backgroundColor: (props) => (props.getMonth === props.id ? 'green' : 'grey'),
        fontSize: 14,
        borderRight: '1px solid White',
        color: theme.palette.common.white,
        // color: theme.palette.common.white,
        padding: '5px'
    },
    body: {
        backgroundColor: 'lightgrey',
        fontSize: 14,
        borderRight: '1px solid white',
        color: theme.palette.common.black,
        padding: '5px'
    }
}))(TableCell);

const BodyCell = withStyles((theme) => ({
    body: {
        fontSize: 12,
        fontWeight: 500,
        borderRight: '1px solid rgba(0,0,0,0.3)',
        padding: '3px 5px',
        fontWeight: 900
    }
}))(TableCell);
const BlankCell = withStyles((theme) => ({
    body: {
        border: 'none',
        borderRight: '1px solid rgba(0,0,0,0.3)',
        padding: '3px 5px'
    }
}))(TableCell);

const TotalCell = withStyles((theme) => ({
    body: {
        background: 'black',
        color: 'white',
        fontSize: 12,
        fontWeight: 500,
        borderRight: '1px solid white',
        padding: '5px 5px',
        fontWeight: 900
    }
}))(TableCell);

const RenderMonth = (props) => {
    let { list, Actual, Forcost, Target, Variance, WIP, totalProfit, demo, fleet, order, uc, retail } = props;
    let TotalMonth = list.reduce((a, b) => {
        var index = a.find((item) => item);
        if (index) {
            index[Actual] += b[Actual];
            index[Forcost] += b[Forcost];
            index[Target] += b[Target];
            index[Variance] += b[Variance];
            index[WIP] += b[WIP];
            index[totalProfit] += b[totalProfit];
            index[demo] += b[demo];
            index[fleet] += b[fleet];
            index[order] += b[order];
            index[uc] += b[uc];
            index[retail] += b[retail];

            return a;
        }
        a.push({ ...b });
        return a;
    }, []);

    const getMonth = moment().month();
    const classes = useStyles();
    let Header = ['', 'Target', 'Actual', 'WIP', 'Forecast', 'Variance'];

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <ManiHederCell align="center" colSpan={6} getMonth={getMonth} id={props.id}>
                            {props.name}
                        </ManiHederCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Header?.map((m) => (
                            <ManiHederCell align="center" getMonth={getMonth} id={props.id}>
                                {m}
                            </ManiHederCell>
                        ))}
                    </TableRow>
                    {list?.length == 0 ? (
                        <TableRow>
                            <BodyCell align="center" colSpan={6} height={400}>
                                No Record Found
                            </BodyCell>
                        </TableRow>
                    ) : (
                        list?.map((o, i) => (
                            <TableRow>
                                <BodyCell align="left">{o.md}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o[Target])}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o[Actual])}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o[WIP])}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o[Forcost])}</BodyCell>

                                <BodyCell align="center" style={{ color: o[Variance] < 0 ? 'red' : 'green' }}>
                                    {o[Variance]}
                                </BodyCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
                <TableBody>
                    {TotalMonth.map((t) => (
                        <TableRow>
                            <TotalCell align="left">Total</TotalCell>
                            <TotalCell align="left">{formatters.ThousandSeparatorWithoutZero(t[Target]) || 0}</TotalCell>
                            <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t[Actual]) || 0}</TotalCell>
                            <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t[WIP]) || 0}</TotalCell>
                            <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t[Forcost]) || 0}</TotalCell>
                            <TotalCell align="center"> {t[Variance] || 0}</TotalCell>
                        </TableRow>
                    ))}
                </TableBody>
                {list?.length > 0 && (
                    <TableBody>
                        <TableRow>
                            <BodyCell align="left">Total profit</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.CurrencyThousandSeparatorWithoutZero(TotalMonth[0][totalProfit]) || 0}</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Profit per unit </BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">
                                {formatters.CurrencyThousandSeparatorWithoutZero(TotalMonth[0][totalProfit] / TotalMonth[0][Actual]) || 0}
                            </BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Total orders </BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0][order]) || 0}</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Unique Customer</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0][uc]) || 0}</BodyCell>
                            <TotalCell align="center">Profit</TotalCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Fleet</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0][fleet])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0][fleet] / TotalMonth[0][Actual]) * 100) || 0}%</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Retail</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0][retail])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0][retail] / TotalMonth[0][Actual]) * 100) || 0}%</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Demo</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0][demo])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0][demo] / TotalMonth[0][Actual]) * 100) || 0}%</BodyCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
const RenderQuater = (props) => {
    const { list, m1, m2, m3 } = props;
    let Arr = [];
    let TotalList = list.forEach((q) => {
        Arr.push({
            totalTarget: q[`${m1}Target`] + q[`${m2}Target`] + q[`${m3}Target`],
            totalActual: q[`${m1}WIP`] + q[`${m2}WIP`] + q[`${m3}WIP`],
            totalWIP: q[`${m1}WIP`] + q[`${m2}WIP`] + q[`${m3}WIP`],
            totalForcost: q[`${m1}Forcost`] + q[`${m2}Forcost`] + q[`${m3}Forcost`],
            totalVariance: q[`${m1}Variance`] + q[`${m2}Variance`] + q[`${m3}Variance`],
            totalActualProfit: q[`${m1}ActualProfit`] + q[`${m2}ActualProfit`] + q[`${m3}ActualProfit`],
            totalOrders: q[`${m1}Orders`] + q[`${m2}Orders`] + q[`${m3}Orders`],
            totalUniqueCustomers: q[`${m1}UniqueCustomers`] + q[`${m2}UniqueCustomers`] + q[`${m3}UniqueCustomers`],
            totalfleet: q[`${m1}Fleet`] + q[`${m2}Fleet`] + q[`${m3}Fleet`],
            totalRetail: q[`${m1}Retail`] + q[`${m2}Retail`] + q[`${m3}Retail`],
            totalDemo: q[`${m1}Demo`] + q[`${m2}Demo`] + q[`${m3}Demo`],
            md: q.md
        });
    });

    let TotalMonth = Arr.reduce((a, b) => {
        var index = a.find((item) => item);
        if (index) {
            index.totalTarget += b.totalTarget;
            index.totalActual += b.totalActual;
            index.totalWIP += b.totalWIP;
            index.totalVariance += b.totalVariance;
            index.totalForcost += b.totalForcost;
            index.totalActualProfit += b.totalActualProfit;
            index.totalOrders += b.totalOrders;
            index.totalUniqueCustomers += b.totalUniqueCustomers;
            index.totalfleet += b.totalfleet;
            index.totalRetail += b.totalRetail;
            index.totalDemo += b.totalDemo;

            return a;
        }
        a.push({ ...b });
        return a;
    }, []);

    const getMonth = moment().month();
    const classes = useStyles();
    let Header = ['', 'Target', 'Actual', 'WIP', 'Forecast', 'Variance'];

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <ManiHederCell align="center" colSpan={6} getMonth={getMonth} id={props.id}>
                            {props.name}
                        </ManiHederCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Header?.map((m) => (
                            <ManiHederCell align="center" getMonth={getMonth} id={props.id}>
                                {m}
                            </ManiHederCell>
                        ))}
                    </TableRow>
                    {list?.lenght > 0 ? (
                        <TableRow>
                            <BodyCell align="left" colSpan={6}>
                                No Record Found
                            </BodyCell>
                        </TableRow>
                    ) : (
                        Arr?.map((o, i) => (
                            <TableRow>
                                <BodyCell align="left">{o.md}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o.totalTarget) || 0}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o.totalActual) || 0}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o.totalWIP) || 0}</BodyCell>
                                <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(o.totalForcost) || 0}</BodyCell>

                                <BodyCell align="center" style={{ color: o.totalVariance < 0 ? 'red' : 'green' }}>
                                    {o.totalVariance || 0}
                                </BodyCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
                <TableBody>
                    {list?.length > 0 &&
                        TotalMonth?.map((t) => (
                            <TableRow>
                                <TotalCell align="left">Total</TotalCell>
                                <TotalCell align="left">{formatters.ThousandSeparatorWithoutZero(t.totalTarget)}</TotalCell>
                                <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t.totalActual)}</TotalCell>
                                <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t.totalWIP)}</TotalCell>
                                <TotalCell align="center">{formatters.ThousandSeparatorWithoutZero(t.totalForcost)}</TotalCell>
                                <TotalCell align="center"> {t.totalVariance || 0}</TotalCell>
                            </TableRow>
                        ))}
                </TableBody>
                {TotalMonth?.length > 0 && (
                    <TableBody>
                        <TableRow>
                            <BodyCell align="left">Total profit</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.CurrencyThousandSeparatorWithoutZero(TotalMonth[0]['totalActualProfit']) || 0}</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Profit per unit </BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">
                                {formatters.CurrencyThousandSeparatorWithoutZero(TotalMonth[0]['totalActualProfit'] / TotalMonth[0]['totalActual']) || 0}
                            </BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Total orders </BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0]['totalOrders']) || 0}</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Unique Customer</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0]['totalUniqueCustomers']) || 0}</BodyCell>
                            <TotalCell align="center">Profit</TotalCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Fleet</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0]['totalfleet'])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0]['totalfleet'] / TotalMonth[0]['totalActual']) * 100) || 0}%</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Retail</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0]['totalRetail'])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0]['totalRetail'] / TotalMonth[0]['totalActual']) * 100) || 0}%</BodyCell>
                        </TableRow>
                        <TableRow>
                            <BodyCell align="left">Demo</BodyCell>
                            <BlankCell align="center"></BlankCell>
                            <BodyCell align="center">{formatters.ThousandSeparatorWithoutZero(TotalMonth[0]['totalDemo'])}</BodyCell>
                            <BodyCell align="center">{Math.round((TotalMonth[0]['totalDemo'] / TotalMonth[0]['totalActual']) * 100) || 0}%</BodyCell>
                        </TableRow>
                    </TableBody>
                )}{' '}
            </Table>
        </TableContainer>
    );
};
export default function LeagueDetailScreen(props) {
    const currentYear = moment().year();
    // const getCourter = `Q${moment().quarter()}`;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let name = params.get('name') || '';
    let salesExecID = params.get('id') || '';

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'League Table', active: true },
        { name: name, active: true }
    ];

    const { list } = props;
    const classes = useStyles();

    const [value, setValue] = React.useState('Q1');
    const [state, setState] = React.useState({
        sortedList: [],
        isLoader: true,
        year: currentYear
    });

    useEffect(async () => {
        let monthRange = value == 'ytd' ? null : value;
        let res = await getLeagueTableByData(monthRange, state.year, salesExecID || '');
        if (res.success) {
            let sortedList = res?.data?.list.reduce((a, b) => {
                var index = a.find((item) => item.mad === b.mad);
                if (index) {
                    index.marchOrders = 0;
                    if (value === 'Q1') {
                        index.janActual += b.janActual;
                        index.janForcost += b.janForcost;
                        index.janTarget += b.janTarget;
                        index.janVariance += b.janVariance;
                        index.janWIP += b.janWIP;
                        index.janActualProfit += b.janActualProfit;
                        index.janDemo += b.janDemo;
                        index.janFleet += b.janFleet;
                        index.janOrders += b.janOrders;
                        index.janRetail += b.janRetail;
                        index.janUniqueCustomers += b.janUniqueCustomers;
                        index.febActual += b.febActual;
                        index.febForcost += b.febForcost;
                        index.febTarget += b.febTarget;
                        index.febVariance += b.febVariance;
                        index.febWIP += b.febWIP;
                        index.febActualProfit += b.febActualProfit;
                        index.febDemo += b.febDemo;
                        index.febFleet += b.febFleet;
                        index.febOrders += b.febOrders;
                        index.febRetail += b.febRetail;
                        index.febUniqueCustomers += b.febUniqueCustomers;
                        index.marchActual += b.marchActual;
                        index.marchForcost += b.marchForcost;
                        index.marchTarget += b.marchTarget;
                        index.marchVariance += b.marchVariance;
                        index.marchWIP += b.marchWIP;
                        index.marchActualProfit += b.marchActualProfit;
                        index.marDemo += b.marDemo;
                        index.marFleet += b.marFleet;
                        index.marOrders += +b.marOrders;
                        index.marRetail += b.marRetail;
                        index.marUniqueCustomers += b.marUniqueCustomers;
                    }
                    if (value === 'Q2') {
                        index.aprActual += b.aprActual;
                        index.aprForcost += b.aprForcost;
                        index.aprTarget += b.aprTarget;
                        index.aprVariance += b.aprVariance;
                        index.aprWIP += b.aprWIP;
                        index.mayActual += b.mayActual;
                        index.mayForcost += b.mayForcost;
                        index.mayTarget += b.mayTarget;
                        index.mayVariance += b.mayVariance;
                        index.mayWIP += b.mayWIP;
                        index.juneActual += b.juneActual;
                        index.juneForcost += b.juneForcost;
                        index.juneTarget += b.juneTarget;
                        index.juneVariance += b.juneVariance;
                        index.juneWIP += b.juneWIP;
                        index.aprActualProfit += b.aprActualProfit;
                        index.aprDemo += b.aprDemo;
                        index.aprFleet += b.aprFleet;
                        index.aprOrders += b.aprOrders;
                        index.aprRetail += b.aprRetail;
                        index.aprUniqueCustomers += b.mayUniqueCustomers;
                        index.mayActualProfit += b.mayActualProfit;
                        index.mayDemo += b.mayDemo;
                        index.mayFleet += b.mayFleet;
                        index.mayOrders += b.mayOrders;
                        index.mayRetail += b.mayRetail;
                        index.mayUniqueCustomers += b.mayUniqueCustomers;
                        index.juneActualProfit += b.juneActualProfit;
                        index.juneDemo += b.juneDemo;
                        index.juneFleet += b.juneFleet;
                        index.juneOrders += b.juneOrders;
                        index.juneRetail += b.juneRetail;
                        index.juneUniqueCustomers += b.juneUniqueCustomers;
                    }
                    if (value === 'Q3') {
                        index.julActual += b.julActual;
                        index.julForcost += b.julForcost;
                        index.julTarget += b.julTarget;
                        index.julVariance += b.julVariance;
                        index.julWIP += b.julWIP;
                        index.augActual += b.augActual;
                        index.augForcost += b.augForcost;
                        index.augTarget += b.augTarget;
                        index.augVariance += b.augVariance;
                        index.augWIP += b.augWIP;
                        index.sepActual += b.sepActual;
                        index.sepForcost += b.sepForcost;
                        index.sepTarget += b.sepTarget;
                        index.sepVariance += b.sepVariance;
                        index.sepWIP += b.sepWIP;
                        index.julActualProfit += b.julActualProfit;
                        index.julDemo += b.julDemo;
                        index.julFleet += b.julFleet;
                        index.julOrders += b.julOrders;
                        index.julRetail += b.julRetail;
                        index.julUniqueCustomers += b.julUniqueCustomers;
                        index.augActualProfit += b.augActualProfit;
                        index.augDemo += b.augDemo;
                        index.augFleet += b.augFleet;
                        index.augOrders += b.augOrders;
                        index.augRetail += b.augRetail;
                        index.augUniqueCustomers += b.augUniqueCustomers;
                        index.sepActualProfit += b.sepActualProfit;
                        index.sepDemo += b.sepDemo;
                        index.sepFleet += b.sepFleet;
                        index.sepOrders += b.sepOrders;
                        index.sepRetail += b.sepRetail;
                        index.sepUniqueCustomers += b.sepUniqueCustomers;
                    }
                    if (value === 'Q4') {
                        index.octActual += b.octActual;
                        index.octForcost += b.octForcost;
                        index.octTarget += b.octTarget;
                        index.octVariance += b.octVariance;
                        index.octWIP += b.octWIP;
                        index.novActual += b.novActual;
                        index.novForcost += b.novForcost;
                        index.novTarget += b.novTarget;
                        index.novVariance += b.novVariance;
                        index.novWIP += b.novWIP;
                        index.decActual += b.decActual;
                        index.decForcost += b.decForcost;
                        index.decTarget += b.decTarget;
                        index.decVariance += b.decVariance;
                        index.decWIP += b.decWIP;
                        index.octActualProfit += b.octActualProfit;
                        index.octDemo += b.octDemo;
                        index.octFleet += b.octFleet;
                        index.octOrders += b.octOrders;
                        index.octRetail += b.octRetail;
                        index.octUniqueCustomers += b.octUniqueCustomers;
                        index.novActualProfit += b.novActualProfit;
                        index.novDemo += b.novDemo;
                        index.novFleet += b.novFleet;
                        index.novOrders += b.novOrders;
                        index.novRetail += b.novRetail;
                        index.novUniqueCustomers += b.novUniqueCustomers;
                        index.decActualProfit += b.decActualProfit;
                        index.decDemo += b.decDemo;
                        index.decFleet += b.decFleet;
                        index.decOrders += b.decOrders;
                        index.decRetail += b.decRetail;
                        index.decUniqueCustomers += b.decUniqueCustomers;
                    }

                    if (value === 'ytd') {
                        index.ytdActual += b.ytdActual;
                        index.ytdForcost += b.ytdForcost;
                        index.ytdTarget += b.ytdTarget;
                        index.ytdVariance += b.ytdVariance;
                        index.ytdWIP += b.ytdWIP;
                        index.ytdActualProfit += b.ytdActualProfit;
                        index.ytdDemo += b.ytdDemo;
                        index.ytdFleet += b.ytdFleet;
                        index.ytdOrders += b.ytdOrders;
                        index.ytdRetail += b.ytdRetail;
                        index.ytdUniqueCustomers += b.ytdUniqueCustomers;
                    }
                    // if (value === 'Q1')
                    // if (value === 'Q2')
                    // if (value === 'Q3') {

                    // if (value === 'Q4') {
                    // }
                    // // newMonth.map((k) => {
                    //     index[`${k}Actual`] += b[`${k}Actual`];
                    //     index[`${k}Forcost`] += b[`${k}Actual`];
                    //     index[`${k}Target`] += b[`${k}Actual`];
                    //     index[`${k}Variance`] += b[`${k}Actual`];
                    //     index[`${k}WIP`] += b[`${k}Actual`];
                    // });
                    return a;
                }
                a.push({ ...b });
                return a;
            }, []);
            setState((st) => ({
                ...st,
                sortedList: sortedList,
                isLoader: false
            }));
        }

        // let sortedMonth = Months.filter((j) => j.name === value);
    }, [value, state.year]);

    const handleChange = (event, newValue) => {
        setState((st) => ({
            ...st,
            isLoader: true
        }));
        setValue(newValue);
    };
    const handleSelect = (event) => {
        const { name, value } = event.target;
        setState((st) => ({
            ...st,
            year: value
        }));
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} />{' '}
                </Grid>
                <Grid item xs={2}>
                    <SelectBox List={YearList} value={state.year} onChange={handleSelect} style={{ marginTop: 10 }} />
                </Grid>
            </Grid>

            <TabContext value={value}>
                {/* <AppBar position="static"> */}
                <TabList onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Quarter-1" value={'Q1'} />
                    <Tab label="Quarter-2" value={'Q2'} />
                    <Tab label="Quarter-3" value={'Q3'} />
                    <Tab label="Quarter-4" value={'Q4'} />
                    <Tab label="YTD" value={'ytd'} />
                </TabList>
                <>
                    {state.isLoader ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <TabPanel value={'Q1'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="January"
                                            id={0}
                                            list={state.sortedList}
                                            Actual={'janActual'}
                                            Forcost={'janForcost'}
                                            Target={'janTarget'}
                                            Variance={'janVariance'}
                                            WIP={'janWIP'}
                                            totalProfit={'janActualProfit'}
                                            demo={'janDemo'}
                                            fleet={'janFleet'}
                                            order={'janOrders'}
                                            uc={'janUniqueCustomers'}
                                            retail={'janRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="February"
                                            id={1}
                                            list={state.sortedList}
                                            Actual={'febActual'}
                                            Forcost={'febForcost'}
                                            Target={'febTarget'}
                                            Variance={'febVariance'}
                                            WIP={'febWIP'}
                                            totalProfit={'febActualProfit'}
                                            demo={'febDemo'}
                                            fleet={'febFleet'}
                                            order={'febOrders'}
                                            uc={'febUniqueCustomers'}
                                            retail={'febRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="March"
                                            id={2}
                                            list={state.sortedList}
                                            Actual={'marchActual'}
                                            Forcost={'marchForcost'}
                                            Target={'marchTarget'}
                                            Variance={'marchVariance'}
                                            WIP={'marchWIP'}
                                            totalProfit={'marchActualProfit'}
                                            demo={'marDemo'}
                                            fleet={'marFleet'}
                                            order={'marOrders'}
                                            uc={'marUniqueCustomers'}
                                            retail={'marRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 1" list={state.sortedList} m1={'jan'} m2={'feb'} m3={'mar'} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q2'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="April"
                                            id={3}
                                            list={state.sortedList}
                                            Actual={'aprActual'}
                                            Forcost={'aprForcost'}
                                            Target={'aprTarget'}
                                            Variance={'aprVariance'}
                                            WIP={'aprWIP'}
                                            totalProfit={'aprActualProfit'}
                                            demo={'aprDemo'}
                                            fleet={'aprFleet'}
                                            order={'aprOrders'}
                                            uc={'aprUniqueCustomers'}
                                            retail={'aprRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="May"
                                            id={4}
                                            list={state.sortedList}
                                            Actual={'mayActual'}
                                            Forcost={'mayForcost'}
                                            Target={'mayTarget'}
                                            Variance={'mayVariance'}
                                            WIP={'mayWIP'}
                                            totalProfit={'mayActualProfit'}
                                            demo={'mayDemo'}
                                            fleet={'mayFleet'}
                                            order={'mayOrders'}
                                            uc={'mayUniqueCustomers'}
                                            retail={'mayRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="June"
                                            id={5}
                                            list={state.sortedList}
                                            Actual={'juneActual'}
                                            Forcost={'juneForcost'}
                                            Target={'juneTarget'}
                                            Variance={'juneVariance'}
                                            WIP={'juneWIP'}
                                            totalProfit={'juneActualProfit'}
                                            demo={'junDemo'}
                                            fleet={'junFleet'}
                                            order={'junOrders'}
                                            uc={'junUniqueCustomers'}
                                            retail={'junRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 2" id={0} list={state.sortedList} m1={'apr'} m2={'may'} m3={'june'} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q3'}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="July"
                                            id={6}
                                            list={state.sortedList}
                                            Actual={'julActual'}
                                            Forcost={'julForcost'}
                                            Target={'julTarget'}
                                            Variance={'julVariance'}
                                            WIP={'julWIP'}
                                            totalProfit={'julActualProfit'}
                                            demo={'julDemo'}
                                            fleet={'julFleet'}
                                            order={'julOrders'}
                                            uc={'julUniqueCustomers'}
                                            retail={'julRetail'}
                                        />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="August"
                                            id={7}
                                            list={state.sortedList}
                                            Actual={'augActual'}
                                            Forcost={'augForcost'}
                                            Target={'augTarget'}
                                            Variance={'augVariance'}
                                            WIP={'augWIP'}
                                            totalProfit={'augActualProfit'}
                                            demo={'augDemo'}
                                            fleet={'augFleet'}
                                            order={'augOrders'}
                                            uc={'augUniqueCustomers'}
                                            retail={'augRetail'}
                                        />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="September"
                                            id={8}
                                            list={state.sortedList}
                                            Actual={'sepActual'}
                                            Forcost={'sepForcost'}
                                            Target={'sepTarget'}
                                            Variance={'sepVariance'}
                                            WIP={'sepWIP'}
                                            totalProfit={'sepActualProfit'}
                                            demo={'sepDemo'}
                                            fleet={'sepFleet'}
                                            order={'sepOrders'}
                                            uc={'sepUniqueCustomers'}
                                            retail={'sepRetail'}
                                        />{' '}
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 3" id={0} list={state.sortedList} m1={'jul'} m2={'aug'} m3={'sep'} />{' '}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={'Q4'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="October"
                                            id={9}
                                            list={state.sortedList}
                                            Actual={'octActual'}
                                            Forcost={'octForcost'}
                                            Target={'octTarget'}
                                            Variance={'octVariance'}
                                            WIP={'octWIP'}
                                            totalProfit={'octActualProfit'}
                                            demo={'octDemo'}
                                            fleet={'octFleet'}
                                            order={'octOrders'}
                                            uc={'octUniqueCustomers'}
                                            retail={'octRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="November"
                                            id={10}
                                            list={state.sortedList}
                                            Actual={'novActual'}
                                            Forcost={'novForcost'}
                                            Target={'novTarget'}
                                            Variance={'novVariance'}
                                            WIP={'novWIP'}
                                            totalProfit={'novActualProfit'}
                                            demo={'novDemo'}
                                            fleet={'novFleet'}
                                            order={'novOrders'}
                                            uc={'novUniqueCustomers'}
                                            retail={'novRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderMonth
                                            name="December"
                                            id={11}
                                            list={state.sortedList}
                                            Actual={'decActual'}
                                            Forcost={'decForcost'}
                                            Target={'decTarget'}
                                            Variance={'decVariance'}
                                            WIP={'decWIP'}
                                            totalProfit={'decActualProfit'}
                                            demo={'decDemo'}
                                            fleet={'decFleet'}
                                            order={'decOrders'}
                                            uc={'decUniqueCustomers'}
                                            retail={'decRetail'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RenderQuater name="Quarter 4" id={0} list={state.sortedList} m1={'oct'} m2={'nov'} m3={'dec'} />
                                    </Grid>
                                </Grid>{' '}
                            </TabPanel>

                            <TabPanel value={'ytd'}>
                                {' '}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <RenderMonth
                                            name="YTD"
                                            id={0}
                                            list={state.sortedList}
                                            Actual={'ytdActual'}
                                            Forcost={'ytdForcost'}
                                            Target={'ytdTarget'}
                                            Variance={'ytdVariance'}
                                            WIP={'ytdWIP'}
                                            totalProfit={'ytdActualProfit'}
                                            demo={'ytdDemo'}
                                            fleet={'ytdFleet'}
                                            order={'ytdOrders'}
                                            uc={'ytdUniqueCustomers'}
                                            retail={'ytdRetail'}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </>
                    )}
                </>
            </TabContext>
        </div>
    );
}
