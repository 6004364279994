import { CircularProgress, Grid, TableContainer } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AppContext from '../../../../../App/AppContext';
import {
    addReportingBudget,
    addReportingVehicleBudget,
    addSalesCategoryTargetBudget,
    getNewUsedSalesCategoryTargetBudget,
    getNewUsedVehicleReportingBudget,
    getReportingBudget
} from '../../../../../Core/Service/ReportScreens-service';
import formatters from '../../../../../Core/Grid/inputFormatter';
import moment from 'moment';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles({
    table: {
        minWidth: 1750,
        '& .MuiTableCell-root': {
            padding: '10px 5px'
        }
    },
    tableRowStyle: {
        backgroundColor: '#E0E0E0'
    },
    totalsContainerStyle: {
        fontWeight: 600
    }
});

const MonthShortNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const NewUsedReportingBudgetTable = (props) => {
    const [state, setState] = useState({
        isRecords: false,
        rows: [],
        isActive: false,
        category: '',
        columnTotal: {},
        rows: [],
        calculatedRows: [],
        isActive: false,
        totals: {},
        changeRecordIds: [],
        isChangeTrig: false
    });
    const [modelTypeList, setModelTypeList] = useState([]);
    const [showCircularLoader, setShowCircularLoader] = useState(true);
    const [calculatedExpenseList, setCalculatedExpenseList] = useState([]);
    const { showToast } = useContext(AppContext);
    const classes = useStyles();

    const pullExpenseBudgetGridData = async () => {
        setShowCircularLoader(true);
        let sortedModelList = props.modelList.map((q) => ({ ...q, data: [] }));
        let res = await getNewUsedSalesCategoryTargetBudget(props.vehicleType, props.yearCode?.id, props.selectedTargetType.id, props.selectedCategories.id);
        let tempResData = res.data.targetData || [];
        if (sortedModelList.length > 0) {
            tempResData.forEach((p) => {
                let objIndex = sortedModelList.findIndex((o) => o.opid.trim() == p.opid);

                if (objIndex > -1) {
                    sortedModelList[objIndex].data.push({ ...p, ttid: props.selectedTargetType.id });
                } else {
                    sortedModelList.push({
                        opid: p.opid,
                        name: p.name,
                        data: [{ ...p }]
                    });
                }
            });

            sortedModelList.forEach((r) => {
                let list2 = MonthShortNameList.map((m) => {
                    let obj = r.data.find((p) => moment(p.td).format('DD-MMM-YYYY').includes(`${m}`));
                    if (obj) {
                        return obj;
                    } else {
                        return {
                            ttid: props.selectedTargetType.id,
                            fc: props.vehicleType,
                            opid: r.opid,
                            id: null,
                            name: r.name,
                            td: moment(`01/${m}/${props.yearCode?.id}`).format('DD-MMM-YYYY'),
                            vu: 0
                        };
                    }
                });
                r.data = list2;
            });

            setState((st) => {
                return { ...st, isRecords: true, isChangeTrig: false };
            });
        } else {
            setState((st) => {
                return { ...st, isRecords: false };
            });
        }
        setModelTypeList(sortedModelList);
        setShowCircularLoader(false);
    };

    useEffect(() => {
        pullExpenseBudgetGridData();
    }, [props.yearCode?.id, props.selectedCategories?.id]);

    useEffect(() => {
        props.handleActionTriggerCheck(state.isChangeTrig);
    }, [state.isChangeTrig]);

    useEffect(() => {
        let tempArr = [...modelTypeList];
        tempArr.forEach((t) => {
            let vuTotal = 0;
            t.data.forEach((q) => {
                vuTotal += +q.vu;
            });

            t.allVuAmount = vuTotal;
        });

        let colCalculation = {
            modelTypeJanVuTotal: 0,
            modelTypeFebVuTotal: 0,
            modelTypeMarVuTotal: 0,
            modelTypeAprVuTotal: 0,
            modelTypeMayVuTotal: 0,
            modelTypeJunVuTotal: 0,
            modelTypeJulVuTotal: 0,
            modelTypeAugVuTotal: 0,
            modelTypeSepVuTotal: 0,
            modelTypeOctVuTotal: 0,
            modelTypeNovVuTotal: 0,
            modelTypeDecVuTotal: 0,
            modelTypeVuTotalTotal: 0
        };

        let vuTotals = 0;
        tempArr.forEach((w) => {
            vuTotals += w.allVuAmount;
            w.data.forEach((q) => {
                MonthShortNameList.map((m) => {
                    if (moment(q.td).format('DD-MMM-YYYY').includes(`${m}`)) {
                        colCalculation[`modelType${m}VuTotal`] += q.vu;
                    }
                });
            });
            colCalculation.modelTypeVuTotalTotal = vuTotals;
        });
        tempArr.push({ name: 'Total', data: colCalculation });

        setCalculatedExpenseList(tempArr);
    }, [modelTypeList]);

    const gridInputChangeHandler = (index, opid) => (e) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.opid == opid);
        tempList[objIndex].data[index] = {
            ...tempList[objIndex].data[index],
            [e.target.name]: +e.target.value,
            isChanged: true
        };

        setModelTypeList(tempList);
        setState((st) => {
            return { ...st, isChangeTrig: true };
        });
    };

    const InputWrapper = (index, opid, TargetUnits, TargetAmount) => {
        return (
            <div style={{ display: 'flex' }}>
                <input title={`${TargetUnits}`} type="number" name={'vu'} onChange={gridInputChangeHandler(index, opid)} value={TargetUnits} className="pa-4" />
            </div>
        );
    };

    const handleSubmit = async () => {
        let tempModelTypeList = [...modelTypeList];

        let data = [];
        tempModelTypeList.forEach((f) => {
            let tempData = f.data
                .filter((j) => j.isChanged)
                .map(({ expenseCategoryName, isChanged, ...q }) => ({
                    reportingSalesCategoryTargetID: q.id,
                    reportingSalesCategoryTargetTypeID: q.ttid,
                    reportingSalesCategoryTargetDate: q.td,
                    reportingSalesCategoryTargetUnit: q.vu,
                    reportingSalesCategoryTargetOperatorID: q.opid,
                    reportingSalesTargetCategoryID: +props.selectedCategories.id
                }));
            data = [...data, ...tempData];
        });

        let filterPostData = {
            TargetType: props.vehicleType,
            SalesCategoryTargets: data
        };

        let res = await addSalesCategoryTargetBudget(filterPostData);
        if (res.success) {
            showToast('Save Successfully');
            pullExpenseBudgetGridData();
        }
    };

    useEffect(() => {
        props.childFunc.current = handleSubmit;
    }, [modelTypeList]);

    const setAmountToAllOnChamge = (index, opid) => (e) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.opid == opid);
        tempList[objIndex][e.target.name] = +e.target.value;
        setModelTypeList(tempList);
    };
    const setAmountToAllHandler = (index, opid) => {
        let tempList = [...modelTypeList];
        let objIndex = tempList.findIndex((x) => x.opid == opid);
        tempList[objIndex].data.forEach((o) => {
            o.vu = tempList[objIndex].setAmountToAll;
            o.isChanged = true;
        });
        setModelTypeList(tempList);
        setState((st) => {
            return { ...st, isRecords: true, isChangeTrig: true };
        });
    };
    const ModelColumnFormatter = (index, opid, name, setAmountToAll) => {
        return (
            <>
                <div>{name}</div>

                <div style={{ display: 'flex' }}>
                    <input
                        type="number"
                        name={'setAmountToAll'}
                        onChange={setAmountToAllOnChamge(index, opid)}
                        value={setAmountToAll}
                        className="pa-4"
                        style={{ width: '90%' }}
                    />

                    <DoubleArrowIcon
                        fontSize="small"
                        color="secondary"
                        style={{ verticalAlign: 'center', cursor: 'pointer' }}
                        onClick={() => setAmountToAllHandler(index, opid)}
                    />
                </div>
            </>
        );
    };

    return (
        <Grid container spacing={2} style={{ paddingTop: '10px' }} justifyContent="center">
            {showCircularLoader ? (
                <Grid item>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 320px)' }} className="input-Number-remove-spinners">
                        <Table className={classes.table} stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={200}>Operator Sales Exec</TableCell>
                                    {MonthShortNameList.map((p) => {
                                        return (
                                            <TableCell align="center">
                                                <div>{p}</div>
                                                {/* <div>Unit</div> */}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell width={100} align="right">
                                        <div> Total</div>
                                        {/* <div>Unit</div> */}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!state.isRecords ? (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            No Records
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {calculatedExpenseList.map((t, index) => {
                                            return (
                                                <>
                                                    {!t.opid ? (
                                                        <TableRow>
                                                            <TableCell component="th" scope="row" className={classes.totalsContainerStyle}>
                                                                {t.name}
                                                            </TableCell>
                                                            {MonthShortNameList.map((p) => {
                                                                return (
                                                                    <TableCell align="center" className={classes.totalsContainerStyle}>
                                                                        {formatters.ThousandSeparatorWithoutZero(t.data[`modelType${p}VuTotal`])}
                                                                    </TableCell>
                                                                );
                                                            })}

                                                            <TableCell align="right" className={classes.totalsContainerStyle}>
                                                                {formatters.ThousandSeparatorWithoutZero(t.data.modelTypeVuTotalTotal)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow>
                                                            {/* <TableCell component="th" scope="row">
                                                                {t.name}
                                                            </TableCell> */}
                                                            <TableCell component="th" scope="row" style={{ borderRight: '1px solid #e0e0e0' }}>
                                                                {ModelColumnFormatter(index, t.opid, t.name, t.setAmountToAll)}
                                                            </TableCell>
                                                            {t.data.map((p, i) => {
                                                                return <TableCell align="center">{InputWrapper(i, t.opid, p.vu, p.vt)}</TableCell>;
                                                            })}

                                                            <TableCell align="right" className={classes.totalsContainerStyle}>
                                                                {formatters.ThousandSeparatorWithoutZero(t.allVuAmount)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    );
};

export default NewUsedReportingBudgetTable;
