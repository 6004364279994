import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import TreeImg from './assests/tree.png';
import { getSavePaperData } from '../../Core/Service/saveTree-service';

const TreeImgLogo = () => {
    const [state, setState] = useState({
        val: 0,
        treeCount: 0
    });
    useEffect(async () => {
        if (state.val == 0) {
            let res = await getSavePaperData();
            let ResData = res.data.documents[0]?.documentsProcessed;
            saveTrees(ResData);
        }
    }, [state.val]);

    const saveTrees = (values) => {
        setState((st) => {
            let newSt = { ...st };
            if (values >= 12000) {
                let treeVal = values / 12000;
                let newVal = Math.round(treeVal);
                newSt.treeCount = newVal;
            } else {
                newSt.treeCount = 0;
            }
            return newSt;
        });
    };
    return (
        <Badge badgeContent={state.treeCount} color="secondary" showZero max={9999}>
            <Link to={`/treeImgs/${state.treeCountl}`}>
                <img
                    src={TreeImg}
                    style={{
                        height: 30,
                        width: 25
                    }}
                />
            </Link>
        </Badge>
    );
};
export default TreeImgLogo;
