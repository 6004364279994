import { get, post } from './http-calls';
import { nicoleDownloadFileWithExt } from './ReportScreens-service';

export const getSaleDashDatabyID = async (id, fn) => {
    let res = await get(`SalesDashboard/ByVehicleCategoryID/${id}?fn=${fn}`, { useAuthToken: true });
    return res;
};
export const getNewStockbyID = async (id) => {
    let res = await get(`SalesDashboard/NewStock?id=${id}`, { useAuthToken: true });
    return res;
};
export const getUsedStockabyID = async (id) => {
    let res = await get(`SalesDashboard/UsedStock?id=${id}`, { useAuthToken: true });
    return res;
};

export const getSalesDashboardTabs = async () => {
    let res = await get(`SalesDashboard/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getStockingChargesbyID = async (id) => {
    let res = await get(`StockingCharges?Code=${id}`, { useAuthToken: true });
    return res;
};

export const getLeagueTablePointsbyID = async (id) => {
    let res = await get(`LeagueTablePoints?Id=${id}`, { useAuthToken: true });
    return res;
};

export const postStockingCharges = async (data) => {
    let res = await post(`StockingCharges`, data, { useAuthToken: true });
    return res;
};
export const postFinanceCustomer = async (data) => {
    let res = await post(`Customers/ReportingPortal_FinanceCustomerUpdate`, data, { useAuthToken: true });
    return res;
};
export const postLeagueTablePoints = async (data) => {
    let res = await post(`LeagueTablePoints`, data, { useAuthToken: true });
    return res;
};
export const postFinanceProviders = async (data) => {
    let res = await post(`FinanceProviders/Franchise`, data, { useAuthToken: true });
    return res;
};
export const postCategoryData = async (data) => {
    let res = await post(`FinanceProviders/Franchise/SetVehicleCategory`, data, { useAuthToken: true });
    return res;
};
export const getFinanceList = async () => {
    let res = await get(`FinanceProviders/Franchise`, { useAuthToken: true });
    return res;
};
export const getLeagueTableByData = async (qtr, year, salesExecID) => {
    let res = await get(`Sales/ReportingGetSalesLeagueDetailsBySalesExec?Quarter=${qtr}&Year=${year}&SalesExec=${salesExecID}`, { useAuthToken: true });
    return res;
};
