import React, { useEffect } from 'react';
import { WorkFlowNewUsedProvider, ScreenContext, MarkingType, FixedImage } from './WorkFlowNewUsed.Provider';
import { useContext } from 'react';
import { BasicButtonGroup } from '../../../../../Core/ButtonGroup';
import { Badge, ButtonGroup, Grid, Icon, IconButton, LinearProgress, Menu, MenuItem, Tooltip } from '@material-ui/core';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import PeopleIcon from '@material-ui/icons/People';
import UpdateIcon from '@material-ui/icons/Update';
import './WorkFlowNewUsed.scss';
import JobNotesModal from './JobNotes';
import WorkflowMovementModal from './WorkflowMovement';
import ReturnReasonHistoryModal from './more/ReturnReasonHistory';
import AllDocModal from '../../../workflow-job/more/AllDocModal'; //'./more/AllDocModal';
import doc from '../../../workflow-job/doc.png'; //'./doc.png';
import { useHistory } from 'react-router-dom';
import {
    Reply,
    History,
    ArrowBack,
    AlternateEmailOutlined,
    Explicit,
    CalendarToday,
    BorderColor,
    TextFields,
    ArrowRight,
    ArrowLeft,
    PhotoLibraryOutlined,
    SpeakerNotes,
    Timeline,
    Done,
    DoneAll,
    Close,
    FiberManualRecord,
    ArrowDropDown,
    Image
} from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import { fabric } from 'fabric';

import { DefaultButton, PrimaryButton, SecondaryButton } from '../../../../../Core/FormInput';
import { useWindowSize } from '../../../../../Core/Controls/ScreenResolution';
import AppContext from '../../../../../App/AppContext';
// import WorkflowStep from '../../../workflow-job/WorkflowStep';
import { AppStorage } from '../../../../../Core/Service/storage-service';
import { useState } from 'react';
import { getNewStockDocumentByName } from '../../../../../Core/Service/workflowJobDocument-service';
import { useCallback } from 'react';
import { useRef } from 'react';
import { getNewStockJobDocumentsStamps } from '../../../../../Core/Service/workflowJobsDocumentStamp-service';
import WorkflowStep, { StepWebFormModal } from './WorkflowStep';
import _ from 'lodash';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import HavePendingMarking from '../../../workflow-job/more/ConfirmationOnPendingMarking';
// import DocStamps from '../../../workflow-job/more/Stamps';
import DocStamps from './more/Stamps';
import moment from 'moment';
import OcrDocHistory from './WorkflowOcrDocHistoryModal';
import MailDocModal from './more/MailDoc';
import ReturnReasonWorkflow from './more/ReturnToWorkflow';
import DownloadDocModal from './more/DownloadDoc';
// import OcrDocHistory from '../../../workflow-job/WorkflowOcrDocHistoryModal';

// import { getWorkflowNewUsedJobStep } from '../../../../../Core/Service/workflow-service';

function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('1WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('1WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const WorkflowNewJobDetails = (props) => {
    const docContainer = useRef(null);
    const stampContainer = useRef(null);
    const canvasRef = useRef(null);
    const fabricLoadedRef = useRef(false);
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { showToast, canOverrideMandatorySteps, userRoles, isTopNav } = useContext(AppContext);
    const portalSettings = AppStorage.getPortalSetting();
    const [currentPageNo, setPageNo] = useState(0);

    const {
        screenState,
        state,
        updateState,
        markingState,
        workflowData,
        tabView,
        tabSize,
        dropDownValueSelected,
        getDocumentFromOcrDocId,
        showMail,
        rotateDoc,
        setLoadedStamps,
        addDocumentImagesData,
        startMarkingMode,
        stopMarkingMode,
        getDocumentHistory,
        showNotes,
        hideNotesModal,
        notesAdded,
        showHistory,
        tryToSaveWorkflowData,
        showReturnReason,
        hideReturnReason,
        showReturnReasonHistory,
        showAllDoc,
        hideReturnReasonHistory,
        hideDownload,
        hideHistoryModal,
        hasPrev,
        hasNext,
        markingTypeSelection,
        hasAnythingToSave,
        saveStampData,
        showOcrDocHistoryModal,
        hideAllDoc,
        showDownload,
        hideOcrDocHistoryModal,
        hideMail,
        btnLoader,
        saveCanvasDataForPageNo
    } = useContext(ScreenContext);
    const { allFilled, isReadonly, documentRotateBy, zoomFactor, docInfoToMail } = screenState;
    const saveBtnEnabled = !isReadonly && (canOverrideMandatorySteps || allFilled);
    let history = useHistory();

    let mainWorkflowID = history.location.pathname.split('/')[3];

    let isArchivedJob = mainWorkflowID == 7;

    const workflowButtonClicked = (btn) => {
        updateState({ selectedWorkflowId: btn.id });
    };

    const saveAndRefresh = async () => {
        let res = await saveStampData();
        console.log('saveAndRefresh', res);

        //need to reload the newly saved document for new type of stamping
        if ((props.canUseNewStamp && res.success) || (res.success && !portalSettings.portalSettingSaveNewDoc)) {
            loadDocument(state.selectedOcrDocId, res.data.newFileName);
        }
    };

    //below logic to open menu for brush color picker.
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setColor = (color) => () => {
        if (markingState.markingType !== MarkingType.FreeDrawing) {
            markingTypeSelection(null, MarkingType.FreeDrawing);
        }
        window.fabricCanvas.freeDrawingBrush.color = color;
        handleClose();
    };

    const pullDocument_V2 = useCallback(
        async (docId, docName, pageNo = 1) => {
            if (!stampContainer.current) {
                return;
            }
            let { scrollWidth, scrollHeight } = stampContainer?.current;
            return getNewStockDocumentByName(docName, pageNo, scrollWidth).then((res) => {
                if (pageNo === 1) {
                    setTimeout(() => {
                        window.fabricCanvas?.setHeight(scrollHeight);
                        window.fabricCanvas?.setWidth(scrollWidth);
                    }, 500);
                }
                addDocumentImagesData(docId, docName, res.imgBase64Src);
                return res.hasMorePage && pullDocument_V2(docId, docName, pageNo + 1);
            });
        },
        [stampContainer]
    );

    const pullDocument_V1 = async (docId, name, pageNo = 1) => {
        return getNewStockDocumentByName(name, pageNo).then((res) => {
            addDocumentImagesData(docId, name, res.imgBase64Src);
            return res.hasMorePage && pullDocument_V1(docId, name, pageNo + 1);
        });
    };

    const loadDocument = async (docId, docName) => {
        !props.canUseNewStamp && setLoadedStamps([]);
        setPageNo(0);
        console.log(state.selectedStockID, 'state.selectedStockID', 'form loaddoc');
        getDocumentHistory(state.selectedStockID, docId).then((res) => {
            if (res.success) {
                let rec = res.data;
                updateState({ selectedOcrDocHistoryCount: rec.length, selectedDocHistory: rec });
            }
        });

        const isPdf = docName.toLowerCase().trim().endsWith('.pdf');
        updateState({ loadingDoc: true, isSelectedFilePdf: isPdf, imagesFromUrl: [], currentVisibleDocName: docName });

        (props.canUseNewStamp ? pullDocument_V2(docId, docName) : pullDocument_V1(docId, docName)).finally(() => {
            updateState({ loadingDoc: false });
        });

        if (!props.canUseNewStamp) {
            getNewStockJobDocumentsStamps(state.selectedStockID, docId, docName).then((res) => {
                const { success, data } = res;
                if (success) {
                    const allSavedStamps = data?.res?.allStamps;
                    setLoadedStamps(allSavedStamps);
                }
            });
        }
    };
    const downloadDocument = async (docId, docName) => {
        rotateDoc(0);
        props.canUseNewStamp && stopMarkingMode();
        if (docName) {
            if (state.selectedOcrDocId !== docId || state.selectedOcrDocName !== docName) {
                loadDocument(docId, docName);
            }
        } else {
            updateState({ isSelectedFilePdf: false, imagesFromUrl: [] });
        }
    };

    const showAlldocState = (showdoc, id) => {
        updateState({ tabView: false });
        downloadDocument(showdoc, id);
    };

    const HandelMailDocument = (e) => {
        showMail({ ocrDocumentName: e.ocrDocName, docName: e.docName, ocrDocIdToMail: e.ocrDocId });
    };

    const DownloadOcrDocument = async (e, isMultiVersion) => {
        console.log('NewVehicleStock DownloadOcrDocument ');
        showDownload({
            ocrDocName: e.docName,
            ocrDocId: e.ocrDocId,
            // SendAllVersions: isMultiVersion,
            stockID: state.selectedStockID,
            docName: `${e.ocrDocName}`,
            createdAt: moment(e.createdAt).format('DD-MMM-YYYY HH:mm'),
            isNew: true
        });
    };

    const handleDocumentAction = (e) => {
        const { eventType, doc, ocrDocumentId, step } = e;
        switch (eventType) {
            case 'VIEW':
                downloadDocument(ocrDocumentId, doc.docName);
                break;
            case 'MAIL':
                showMail({ ocrDocumentName: step.ocrDocumentName, docName: doc.docName, ocrDocIdToMail: ocrDocumentId });
                break;
            default:
                showToast(`Event Type: ${eventType} is not implement yet.`);
        }
    };

    let timeout;
    let updateCanvasDataToState = useCallback(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            saveCanvasDataForPageNo(
                currentPageNo,
                window.fabricCanvas.toDatalessJSON(),
                window.fabricCanvas.toDataURL(),
                window.fabricCanvas.getActiveObjects().length > 0
            );
        }, 100);
    }, [currentPageNo]);

    useEffect(() => {
        if (!canvasRef.current) return;
        if (!fabricLoadedRef.current) {
            window.fabricCanvas = new fabric.Canvas('main-canvas');
            window.fabricCanvas.freeDrawingBrush.width = 1.4;
            window.fabricCanvas.freeDrawingBrush.color = 'red';
            fabricLoadedRef.current = true;
        }
        if (fabricLoadedRef.current) {
            window.fabricCanvas.on('object:added', updateCanvasDataToState);
            window.fabricCanvas.on('object:removed', updateCanvasDataToState);
            window.fabricCanvas.on('object:modified', updateCanvasDataToState);
            window.fabricCanvas.on('selection:created', updateCanvasDataToState);
            window.fabricCanvas.on('selection:cleared', updateCanvasDataToState);

            return () => {
                window.fabricCanvas.off('object:added', updateCanvasDataToState);
                window.fabricCanvas.off('object:removed', updateCanvasDataToState);
                window.fabricCanvas.off('object:modified', updateCanvasDataToState);
                window.fabricCanvas.off('selection:created', updateCanvasDataToState);
                window.fabricCanvas.off('selection:cleared', updateCanvasDataToState);
            };
        }
    }, [canvasRef.current, updateCanvasDataToState]);

    useEffect(() => {
        if (!window.fabricCanvas) {
            return;
        }
        if (markingState.markingType === MarkingType.FreeDrawing) {
            window.fabricCanvas.isDrawingMode = true;
        } else {
            window.fabricCanvas.isDrawingMode = false;
        }
    }, [markingState.markingType]);

    useEffect(() => {
        if (!state.currentVisibleDocName) {
            setTimeout(() => {
                state.workflowSteps.find((st) => {
                    const ocrDoc = getDocumentFromOcrDocId(st.ocrDocumentId);
                    if (ocrDoc && ocrDoc.docName) {
                        handleDocumentAction({
                            eventType: 'VIEW',
                            stepId: st.stepId,
                            ocrDocumentId: st.ocrDocumentId,
                            doc: ocrDoc,
                            st
                        });
                        return true;
                    } else return false;
                });
            }, 1000);
        }
    }, [state.workflowSteps]);

    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const isCustomer = userRoles.includes('customer');
    const isAuditor = userRoles.includes('auditor');
    const haveSomethingToSave = props.canUseNewStamp ? hasAnythingToSave() : markingState.stamps.some((c) => c.isNew);

    const showDoc = !state.tabView || tabSize > 1260;
    const showStep = state.tabView || tabSize > 1260;

    const hideLeftButtonController = () => {
        const axButtons = ['Notes', 'History', 'Save', 'SaveAndComplete', 'Return', 'ReturnReasonHistory', 'AllDocuments'];
        let shouldHide = true;
        axButtons.map((axB) => {
            if (shouldShowButton(axB)) {
                shouldHide = false;
            }
        });
        return shouldHide;
    };

    const shouldShowButton = (buttonName) => {
        if (isInternal || isTechnician) {
            return true;
        }

        switch (buttonName) {
            case 'Notes':
                return (isCustomer && portalSettings.canCustomerViewNotesButton) || (isAuditor && portalSettings.canAuditorViewNotesButton);
            case 'History':
                return (isCustomer && portalSettings.canCustomerViewHistoryButton) || (isAuditor && portalSettings.canAuditorViewHistoryButton);
            case 'Save':
                return (isCustomer && portalSettings.canCustomerViewSaveButton) || (isAuditor && portalSettings.canAuditorViewSaveButton);
            case 'SaveAndComplete':
                return (isCustomer && portalSettings.canCustomerViewSaveAndCompleteButton) || (isAuditor && portalSettings.canAuditorViewSaveAndCompleteButton);
            case 'Return':
                return (isCustomer && portalSettings.canCustomerViewReturnButton) || (isAuditor && portalSettings.canAuditorViewReturnButton);
            case 'ReturnReasonHistory':
                return (
                    (isCustomer && portalSettings.canCustomerViewReturnReasonHistoryButton) ||
                    (isAuditor && portalSettings.canAuditorViewReturnReasonHistoryButton)
                );
            case 'AllDocuments':
                return (isCustomer && portalSettings.canCustomerViewAllDocumentsButton) || (isAuditor && portalSettings.canAuditorViewAllDocumentsButton);
            default:
                return false;
        }
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    const clearCanvasAndStopMarkingMode = () => {
        window.fabricCanvas?.clear();
        stopMarkingMode();
    };

    const checkAndStopMarkingMode = () => {
        if (haveSomethingToSave) {
            setShowConfirmation(true);
        } else {
            props.canUseNewStamp ? clearCanvasAndStopMarkingMode() : stopMarkingMode();
        }
    };

    const getScreenOffset = (isFlag) => {
        if (hideLeftButtonController()) {
            if (isTopNav) {
                return '210px';
            } else {
                return '137px';
            }
        } else {
            if (isTopNav) {
                return '256px';
            } else {
                return '185px';
            }
        }
    };

    const deleteActiveFabricObjects = () => {
        if (window.fabricCanvas) {
            let actvObjs = window.fabricCanvas.getActiveObjects();
            actvObjs.forEach((o) => window.fabricCanvas.remove(o));
        }
    };

    const displayDoc = () => {
        if (state.imagesFromUrl.length === 0) {
            return <img src={doc} alt="No document selected" className="sample-doc" />;
        } else {
            if (props.canUseNewStamp) {
                return <img alt="Document's" src={state.imagesFromUrl[currentPageNo]} className={`shown-doc deg-${documentRotateBy}`} />;
            } else {
                let width = docContainer?.current?.clientWidth;
                width = parseInt(width) * zoomFactor;
                let styleObj = {};
                if (documentRotateBy === 0 || documentRotateBy === 180) {
                    styleObj.width = `calc(${width}px - 6px)`;
                } else {
                    styleObj.height = `calc(${width}px - 6px)`;
                }

                return state.imagesFromUrl.map((imgBase64, i) => (
                    <img key={i} alt="Document's" src={imgBase64} className={`shown-doc deg-${documentRotateBy}`} style={styleObj} />
                ));
            }
        }
    };

    const saveWorkflowData = async (markComplete) => {
        const res = await tryToSaveWorkflowData(markComplete);
        showToast(res.message);
    };

    const [newState, setNewState] = useState(true);

    let checkisEmptyURL = _.isEmpty(workflowData?.jobBasicDetail.defaultWebForm);
    let showDefaultModal = newState && !checkisEmptyURL;

    return (
        <div>
            <BasicButtonGroup
                margin={6}
                className="workflow-buttons"
                selectedId={state.selectedWorkflowId}
                triggerButtonClickOnLoad={false}
                buttons={state.workflows}
                onClick={workflowButtonClicked}
            />
            <div container className="workflow-job-details">
                <div
                    item
                    className="job-basic-details-container"
                    style={{
                        backgroundColor: 'white',
                        color: 'black'
                    }}
                >
                    <div className="job-basic-details">
                        <Tooltip title="Back">
                            <IconButton
                                style={{ color: 'red', padding: 0, margin: '0px 5px' }}
                                component="span"
                                color="Secondary"
                                onClick={() => {
                                    let searchQuery = `&workFlowId=${state.selectedWorkflowId || ''}&ProcessId=${state.selectedProcessId}`;
                                    history.push({
                                        pathname: '/NewSalesListView',
                                        search: searchQuery,
                                        state: {}
                                    });
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>

                        {shouldShowButton('Return') && isArchivedJob && (
                            <Tooltip title="Return From Archived">
                                <IconButton
                                    component="span"
                                    onClick={showReturnReason}
                                    color="Secondary"
                                    style={{ color: isArchivedJob ? 'white' : 'red', marginLeft: '5px', padding: 0 }}
                                >
                                    <Reply size="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {hideLeftButtonController() && (
                            <DefaultButton
                                style={{ padding: '3px 11px', minWidth: '45px', marginRight: '5px' }}
                                title="All Documents"
                                disabled={workflowData.workflowDocs.length === 0}
                                onClick={showAllDoc}
                            >
                                <PhotoLibraryOutlined />
                            </DefaultButton>
                        )}

                        {workflowData.jobBasicDetail && (
                            <div className={`${workflowData.jobBasicDetail.stopFlag && 'set-background-red'}`}>
                                <div>
                                    Book Number :&nbsp; <span>{workflowData.jobBasicDetail.stockbookNumber}</span>
                                </div>
                                <div>
                                    Customer :&nbsp; <span>{workflowData.jobBasicDetail.customerName}</span>
                                </div>
                                <div>
                                    Reg No :&nbsp; <span>{workflowData.jobBasicDetail.registration}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Grid container className="grid">
                    <Grid item className="grid-left-section" style={{ display: `${showStep ? 'block' : 'none'}` }}>
                        <div className="steps-container custom-scroll" style={{ height: `calc(100vh - ${getScreenOffset(true)})` }}>
                            {state.workflowSteps.map((s) => (
                                <WorkflowStep
                                    key={s.stepId}
                                    step={s}
                                    jobId={state.jobId}
                                    technicianJobId={state.technicianJobId}
                                    dropdownValue={workflowData.tempMergedData[s.jsonPropertyName]}
                                    onDropdownChange={dropDownValueSelected}
                                    onDocumentAction={handleDocumentAction}
                                    isTechReadonly={state.isTechReadonly}
                                    jobBasicDetail={workflowData.jobBasicDetail}
                                />
                            ))}
                            {showDefaultModal && (
                                <StepWebFormModal
                                    step={{}}
                                    isTechReadonly={state.isTechReadonly}
                                    closeModal={() => setNewState(false)}
                                    jobId={state.jobId}
                                    technicianJobId={state.technicianJobId}
                                    navigate={true}
                                    screenName={workflowData?.jobBasicDetail?.buttonLabel}
                                    url={workflowData?.jobBasicDetail?.defaultWebForm}
                                />
                            )}
                        </div>
                        {!hideLeftButtonController() && (
                            <div className="actionButton-Container">
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                                        <ChevronLeftIcon />
                                    </span>
                                )}
                                <div className="action-buttons" id="1WorkFlow-Btn">
                                    {shouldShowButton('Notes') && (
                                        <DefaultButton title="Notes" onClick={showNotes} className={workflowData.hasNotes ? 'has-records' : ''}>
                                            <SpeakerNotes />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('History') && (
                                        <DefaultButton title="History" onClick={showHistory} className="has-records">
                                            <Timeline />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('Save') && (
                                        <PrimaryButton
                                            title="Save"
                                            onClick={() => saveWorkflowData(false)}
                                            disabled={!workflowData.hasSomethingToSave || screenState.isReadonly}
                                            isBtnLoader={btnLoader.btn1}
                                        >
                                            <Done />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('SaveAndComplete') && (
                                        <PrimaryButton
                                            title="Save and Complete"
                                            disabled={!saveBtnEnabled}
                                            isBtnLoader={btnLoader.btn2}
                                            onClick={() => saveWorkflowData(true)}
                                        >
                                            <DoneAll />
                                        </PrimaryButton>
                                    )}
                                    {shouldShowButton('Return') && (
                                        <SecondaryButton title="Return" disabled={screenState.isReadonly} onClick={showReturnReason}>
                                            <Reply />
                                        </SecondaryButton>
                                    )}
                                    {shouldShowButton('ReturnReasonHistory') && (
                                        <DefaultButton title="Return Reason History" onClick={showReturnReasonHistory} className="has-records">
                                            <HistoryIcon />
                                        </DefaultButton>
                                    )}
                                    {shouldShowButton('AllDocuments') && (
                                        <DefaultButton title="All Documents" disabled={workflowData.workflowDocs.length === 0} onClick={showAllDoc}>
                                            <PhotoLibraryOutlined />
                                        </DefaultButton>
                                    )}
                                    {/* <DefaultButton title="Log History">
                                <AssignmentIcon />
                            </DefaultButton> */}
                                </div>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                                        <ChevronRightIcon />
                                    </span>
                                )}
                            </div>
                        )}
                    </Grid>
                    <Grid item className="grid-right-section dialog-custom" style={{ display: `${showDoc ? 'block' : 'none'}` }}>
                        {tabView && (
                            <div className="document-close-action">
                                <Tooltip title="Close">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            updateState({ tabView: true });
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        {state.imagesFromUrl.length > 0 && (
                            <>
                                {props.canUseNewStamp && hasPrev(currentPageNo) && (
                                    <div className="doc-page-move go-prev">
                                        <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo - 1)}>
                                            <ArrowLeft />
                                        </IconButton>
                                    </div>
                                )}
                                {props.canUseNewStamp && hasNext(currentPageNo) && (
                                    <div className="doc-page-move go-next">
                                        <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo + 1)}>
                                            <ArrowRight />
                                        </IconButton>
                                    </div>
                                )}
                                <div className="document-action">
                                    {/* <Tooltip title="Rotate by 90'">
                                        <IconButton component="span" onClick={() => rotateDoc()}>
                                            <Rotate90DegreesCcw />
                                        </IconButton>
                                    </Tooltip> */}
                                    {markingState.isStart ? (
                                        <Grid container direction="column" className="marking-stuff">
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    fullWidth
                                                    className="marking-toolbar"
                                                    color="secondary"
                                                    value={markingState.markingType}
                                                    exclusive
                                                    size="small"
                                                    onChange={markingTypeSelection}
                                                >
                                                    {props.canUseNewStamp && (
                                                        <ToggleButton value={MarkingType.FreeDrawing}>
                                                            <FiberManualRecord
                                                                fontSize="small"
                                                                style={{
                                                                    color:
                                                                        markingState.markingType === MarkingType.FreeDrawing
                                                                            ? window.fabricCanvas.freeDrawingBrush.color
                                                                            : 'lightgrey'
                                                                }}
                                                            />
                                                            &nbsp;|
                                                            <Icon onClick={handleClick}>
                                                                <ArrowDropDown />
                                                            </Icon>
                                                        </ToggleButton>
                                                    )}
                                                    <ToggleButton value={MarkingType.Text}>
                                                        <TextFields fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Sign}>
                                                        <BorderColor fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Calendar}>
                                                        <CalendarToday fontSize="small" />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Stamp}>
                                                        <Explicit />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Check}>
                                                        <img alt="Check" src={FixedImage.Check} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.Cross}>
                                                        <img alt="Cross" src={FixedImage.Cross} />
                                                    </ToggleButton>
                                                    <ToggleButton value={MarkingType.NA}>
                                                        <img alt="NA" src={FixedImage.NA} />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>

                                                <Menu
                                                    dense
                                                    anchorEl={anchorEl}
                                                    open={!!anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    onClose={handleClose}
                                                >
                                                    {['red', 'green', 'blue', 'black'].map((clr) => (
                                                        <MenuItem onClick={setColor(clr)}>
                                                            <FiberManualRecord fontSize="small" style={{ color: clr }} />
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Grid>
                                            <Grid item>
                                                <ButtonGroup fullWidth className="marking-action" size="small">
                                                    {props.canUseNewStamp && (
                                                        <PrimaryButton
                                                            variant="contained"
                                                            disabled={!markingState.haveAnySelected}
                                                            onClick={deleteActiveFabricObjects}
                                                        >
                                                            Delete
                                                        </PrimaryButton>
                                                    )}
                                                    <PrimaryButton variant="contained" disabled={!haveSomethingToSave} onClick={saveAndRefresh}>
                                                        Save
                                                    </PrimaryButton>
                                                    <SecondaryButton variant="contained" onClick={checkAndStopMarkingMode}>
                                                        Close
                                                    </SecondaryButton>
                                                </ButtonGroup>
                                                {showConfirmation && (
                                                    <HavePendingMarking
                                                        showConfirmation={showConfirmation}
                                                        removeAndClose={props.canUseNewStamp ? clearCanvasAndStopMarkingMode : stopMarkingMode}
                                                        handleClose={() => setShowConfirmation(false)}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Tooltip title="History of the Document">
                                                <IconButton component="span" onClick={showOcrDocHistoryModal}>
                                                    <Badge
                                                        className="red-on-white"
                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                        badgeContent={state.selectedOcrDocHistoryCount}
                                                        color="primary"
                                                    >
                                                        <History />
                                                    </Badge>
                                                </IconButton>
                                            </Tooltip>
                                            {!isAuditor && (
                                                <Tooltip title="Stamps and Marking">
                                                    <IconButton
                                                        component="span"
                                                        onClick={startMarkingMode}
                                                        color="default"
                                                        className={`${markingState.isStart ? 'stamp-start' : ''}`}
                                                    >
                                                        <AlternateEmailOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <div
                            className={`document-viewer custom-scroll${state.isSelectedFilePdf && state.imagesFromUrl.length ? ' multi-image-from-pdf' : ''}${
                                markingState.markingType ? ' marking-start' : ''
                            }`}
                            style={{ height: `calc(100vh - ${getScreenOffset(false)})` }}
                            ref={docContainer}
                        >
                            <div ref={stampContainer} className="refStampContainer">
                                {state.loadingDoc ? (
                                    <LinearProgress />
                                ) : (
                                    <DocStamps currentPageNo={currentPageNo} docContainer={stampContainer} canUseNewStamp={props.canUseNewStamp} />
                                )}
                                {displayDoc()}
                                {props.canUseNewStamp && (
                                    <div className="canvas-wrapper" style={{ display: `${markingState.isStart ? 'block' : 'none'}` }}>
                                        <canvas ref={canvasRef} id="main-canvas" className="main-canvas" />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="actionButton-Container" style={{ marginTop: 5 }}>
                            <Grid container spacing={1}>
                                {state.selectedDocHistory.map((m) => {
                                    return (
                                        <Grid item>
                                            <SecondaryButton
                                                className="historydoclist"
                                                title={moment(m.createdAt).format('LLL')}
                                                variant={m.docName == state.selectedOcrDocName ? 'contained' : 'outlined'}
                                                onClick={() => downloadDocument(state.selectedOcrDocId, m.docName)}
                                            >
                                                <Image />
                                            </SecondaryButton>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                {screenState.showHistoryModal && <WorkflowMovementModal stockID={state.selectedStockID} onClose={hideHistoryModal} isNew={true} />}
                {screenState.showReturnReasonHistoryModal && (
                    <ReturnReasonHistoryModal stockID={state.selectedStockID} isNew={true} onClose={hideReturnReasonHistory} />
                )}
                {screenState.showNotesModal && <JobNotesModal stockID={state.selectedStockID} onClose={hideNotesModal} onNotesAdded={notesAdded} />}
                {screenState.showAllDocModal && (
                    <AllDocModal
                        allDoc={workflowData.workflowDocs}
                        showDoc={showAlldocState}
                        onClose={hideAllDoc}
                        HandelMailDocument={HandelMailDocument}
                        DownloadOcrDocument={DownloadOcrDocument}
                    />
                )}
                {screenState.showReturnReasonModal && (
                    <ReturnReasonWorkflow
                        workflowId={isArchivedJob ? 7 : state.selectedWorkflowId}
                        StockID={state.selectedStockID}
                        onClose={hideReturnReason}
                        showToast={showToast}
                        ParamProcessId={state.selectedProcessId}
                    />
                )}
                {screenState.showOcrDocHistory && (
                    <OcrDocHistory
                        stockID={state.selectedStockID}
                        ocrDocId={state.selectedOcrDocId}
                        showDoc={downloadDocument}
                        onClose={hideOcrDocHistoryModal}
                        showToast={showToast}
                        updateState={updateState}
                    />
                )}
                {screenState.showMailModal && (
                    <MailDocModal
                        jobId={state.selectedStockID}
                        onClose={hideMail}
                        docInfoToMail={docInfoToMail}
                        jobBasicDetail={workflowData.jobBasicDetail}
                        showToast={showToast}
                    />
                )}
                {screenState.showDownloadModal && (
                    <DownloadDocModal
                        stockID={state.selectedStockID}
                        onClose={hideDownload}
                        docInfoToMail={docInfoToMail}
                        jobBasicDetail={workflowData.jobBasicDetail}
                        showToast={showToast}
                    />
                )}
            </div>
        </div>
    );
};

const Screen = (props) => {
    const {
        portalSettings: { canUseNewStamp }
    } = useContext(AppContext);

    return (
        <WorkFlowNewUsedProvider {...props} canUseNewStamp={canUseNewStamp}>
            <WorkflowNewJobDetails {...props} canUseNewStamp={canUseNewStamp} />
        </WorkFlowNewUsedProvider>
    );
};

export default Screen;
